import { setAppError } from "../Utils/modalMethods";

const mainUrl =
  process.env.REACT_APP_ENV === "development"
    ? process.env.REACT_APP_DEV_API
    : process.env.REACT_APP_PROD_API;

type APIRoute = string;
type AccessToken = string;
type RequestBody = Record<string, any>;

export const APIPost = async (
  route: APIRoute,
  accessToken: AccessToken,
  body: RequestBody,
  responseType: "json" | "blob" = "json" // Nuevo parámetro opcional
): Promise<any> => {
  try {
    const data = await fetch(`${mainUrl}${route}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    return responseType === "blob" ? await data.blob() : await data.json();
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const APIGet = async (
  route: APIRoute,
  accesToken: AccessToken
): Promise<any> => {
  try {
    const data = await fetch(`${mainUrl}${route}`, {
      headers: {
        Authorization: `Bearer ${accesToken}`,
      },
    });
    const parsedData = await data.json();
    return parsedData;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const APIPostProd = async (
  route: APIRoute,
  accessToken: AccessToken,
  body: RequestBody
): Promise<any> => {
  try {
    const data = await fetch(`${process.env.REACT_APP_PROD_API}${route}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const parsedData = await data.json();
    return parsedData;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const APIGetProd = async (
  route: APIRoute,
  accessToken: AccessToken
): Promise<any> => {
  try {
    const data = await fetch(`${process.env.REACT_APP_PROD_API}${route}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const parsedData = await data.json();
    return parsedData;
  } catch (error) {
    setAppError();
  }
};

export const APIPostFile = async (
  route: APIRoute,
  accessToken: AccessToken,
  body: RequestBody
): Promise<any> => {
  const formData = new FormData();
  const keys = Object.keys(body);

  keys.forEach((key) => {
    formData.append(key, body[key]);
  });
  try {
    const data = await fetch(`${mainUrl}${route}`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const parsedData = await data.json();
    return parsedData;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const APIPostImageWithError = async (
  route: APIRoute,
  accessToken: AccessToken,
  body: any
): Promise<any> => {
  try {
    const response = await fetch(`${mainUrl}${route}`, {
      method: "POST",
      body: JSON.stringify({ image: body }),
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return await response.json();
  } catch (error) {
    setAppError("Error de sistema, inténtelo nuevamente.");
    return error;
  }
};

export const APIPostWithError = async (
  route: APIRoute,
  accessToken: AccessToken,
  body: RequestBody
): Promise<any | null> => {
  try {
    const data = await fetch(`${mainUrl}${route}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    if (!data) {
      setAppError();
      return null;
    }
    const parsedData = await data.json();
    if (parsedData && parsedData.error) {
      setAppError(parsedData.error);
      return null;
    }
    return parsedData;
  } catch (error) {
    console.log(error);
    setAppError("Error de sistema, inténtelo nuevamente.");
    return null;
  }
};

export const APIGetWithError = async (
  route: APIRoute,
  accessToken: AccessToken
): Promise<any | null> => {
  try {
    const data = await fetch(`${mainUrl}${route}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    if (!data) {
      setAppError();
      return null;
    }
    const parsedData = await data.json();
    if (parsedData && parsedData.error) {
      setAppError(parsedData.error);
      return null;
    }
    return parsedData;
  } catch (error) {
    console.log(error);
    setAppError("Error de sistema, inténtelo nuevamente.");
    return null;
  }
};
