import { atom } from "jotai";
import { getDefaultStore } from "jotai";
import { NewSigner } from "../Types/Signer.types";
import { Document } from "./documents.jotai";
import { ElectronicSignature } from "./electronicSignatures.jotai";

type Folder = {
  folderName: string;
  folderId: string;
};

type CommonComponentAtomType = {
  showNewFolderModal: boolean;
  showFolderModal: boolean;
  showDocumentDetailModal: boolean;
  showLoadingScreen: boolean;
  showSignatureStatusModal: boolean;
  showSignerEmailModal: boolean;
  showFileUploadModal: boolean;
  showExpirationModal: boolean;
  showFolderNameModal: boolean;
  currentDocument: Document | null;
  currentSignature: ElectronicSignature | null;
  currentSigner: NewSigner | null;
  folder: Folder;
  message: string;
  subMessage: string;
  disclaimer: string;
  errorModal: {
    show: boolean;
    message: string;
    submessage: string;
    disclaimer: string;
  };
  successModal: {
    show: boolean;
    message: string;
    submessage: string;
    disclaimer: string;
  };
};

export const initialCommonComponentAtom: CommonComponentAtomType = {
  showNewFolderModal: false,
  showFolderModal: false,
  showDocumentDetailModal: false,
  showLoadingScreen: false,
  showExpirationModal: false,
  showSignatureStatusModal: false,
  showSignerEmailModal: false,
  showFileUploadModal: false,
  showFolderNameModal: false,
  currentDocument: null,
  currentSignature: null,
  currentSigner: null,
  message: "",
  subMessage: "",
  disclaimer: "",
  folder: {
    folderName: "",
    folderId: "",
  },
  errorModal: {
    show: false,
    message: "",
    submessage: "",
    disclaimer: "",
  },
  successModal: {
    show: false,
    message: "",
    submessage: "",
    disclaimer: "",
  },
};

export const commonComponentAtom = atom<CommonComponentAtomType>(
  initialCommonComponentAtom
);

const store = getDefaultStore();

export const resetCommonComponent = () => {
  store.set(commonComponentAtom, initialCommonComponentAtom);
};

export const setCommonComponents = (params: any) => {
  store.set(commonComponentAtom, { ...initialCommonComponentAtom, ...params });
};
