import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { APIPostWithError } from "../../../Services/authenticated";
import { useDispatch, useSelector } from "react-redux";

import "./styles.css";
import AndesModal from "../../../uiComponents/AndesModal/AndesModal";
import { Box } from "@mui/material";
import AutomaticForm, { Field } from "../../../uiComponents/Form/AutomaticForm";
import { useForm } from "react-hook-form";
import { setLoading } from "../../../Utils/modalMethods";
import { setFolders } from "../../../redux/slices/masterData";
import { RootState } from "../../../redux/rootReducer";
import { produce } from "immer";
import {
  commonComponentAtom,
  resetCommonComponent,
} from "../../../jotai/commonComponentAtom.jotai";
import { useAtomValue } from "jotai";

const FolderNameModal: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { folder } = useAtomValue(commonComponentAtom);
  const { folders } = useSelector((state: RootState) => state.masterData);

  const fields: Field[] = [
    {
      name: "folderName",
      label: "Nombre de la carpeta",
      type: "text",
      required: true,
    },
  ];

  const { control, handleSubmit } = useForm({
    defaultValues: { folderName: folder.folderName },
  });

  const dispatch = useDispatch();

  const onSaveFolder = async (input: any) => {
    const folderName = input.folderName;

    setLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const res = await APIPostWithError(
        "/folders/update-folder-name",
        accessToken,
        {
          folderId: folder.folderId,
          folderName,
        }
      );

      if (res.folderUpdated) {
        resetCommonComponent();
        if (!folders) return;
        const currentFolderIndex = folders.findIndex(
          (f) => f.folderId === folder.folderId
        );
        if (currentFolderIndex === -1) return;
        const updatedFolders = produce(folders, (draft) => {
          if (draft[currentFolderIndex]) {
            draft[currentFolderIndex].folderName = folderName;
          }
        });
        dispatch(setFolders(updatedFolders));
      }
    } catch (e) {
      console.error("Error al crear la carpeta:", e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AndesModal
      message={"Nombre de la carpeta"}
      handleSubmit={handleSubmit(onSaveFolder)}
      handleClose={resetCommonComponent}
    >
      <Box mt={2} width={"100%"}>
        <AutomaticForm control={control} fields={fields} />
      </Box>
    </AndesModal>
  );
};

export default FolderNameModal;
