import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AndesTooltip } from "../AndesTooltip/AndesTooltip";
import { Box } from "@mui/material";
import { colorPallette } from "../../Common/theme";

type Props = {
  icon?: any;
  label?: string;
  onClick?: () => void;
};

const ActionButton: React.FC<Props> = ({ icon, label, onClick }) => {
  return (
    <Box
      onClick={onClick}
      pr={1}
      sx={{
        cursor: "pointer",
        display: "inline-flex",
        alignItems: "center",
      }}
    >
      <AndesTooltip description={label} placement="right">
        {icon && (
          <FontAwesomeIcon
            color={
              label === "Eliminar firmante"
                ? colorPallette.primary
                : colorPallette.dark
            }
            icon={icon}
            style={{
              transition: "all 0.3s ease",
              fontSize: "0.9rem",
            }}
            onMouseOver={(e) => {
              e.currentTarget.style.transform = "rotate(5deg)";
              e.currentTarget.style.opacity = "0.6";
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.transform = "rotate(0deg)";
              e.currentTarget.style.opacity = "1";
            }}
          />
        )}
      </AndesTooltip>
    </Box>
  );
};

export default ActionButton;
