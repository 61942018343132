import React, { useState } from "react";
import DocumentsManagerTable from "../../../components/Tables/DocumentManager/DocumentManagerTable.component";
import FoldersPage from "./FoldersPage/FoldersPage";
import { setCommonComponents } from "../../../jotai/commonComponentAtom.jotai";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBolt,
  faCloudArrowUp,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Box, Button, Stack } from "@mui/material";
import { useHistory } from "react-router-dom";
import TabSelector from "../../../uiComponents/TabSelector/TabSelector";
import { Document } from "../../../jotai/documents.jotai";

type DocumentManager = {
  documents: Document[];
  spaceUsage: number;
};

const DocumentsManager: React.FC<DocumentManager> = ({
  documents,
  spaceUsage,
}) => {
  const [selectedPage, setSelectedPage] = useState(1);

  const history = useHistory();

  return (
    <div className="documents-manager animate">
      <Box sx={{ position: "absolute", zIndex: 9, top: 100 }}>
        <TabSelector
          options={["Todos los documentos", "Por Carpetas"]}
          onChange={(index) => {
            setSelectedPage(index + 1);
          }}
          width={"400px"}
        />
      </Box>
      {selectedPage !== 2 && (
        <Stack
          className="animate"
          sx={{
            position: "absolute",
            right: "48px",
            top: "24px",
            flexDirection: "row",
            justifyContent: "space-between",
            maxWidth: "43vw",
          }}
        >
          <Button
            onClick={() => {
              history.push("/dashboard/documents");
            }}
            variant="contained"
            startIcon={
              <FontAwesomeIcon icon={faBolt} style={{ fontSize: "0.99rem" }} />
            }
            sx={{
              width: "300px",
              maxWidth: "48%",
              height: "54px",
            }}
          >
            Nuevo Documento
          </Button>
          <Button
            onClick={() => {
              setCommonComponents({
                showFileUploadModal: true,
              });
            }}
            variant="contained"
            startIcon={
              <FontAwesomeIcon
                icon={faCloudArrowUp}
                style={{ fontSize: "0.99rem" }}
              />
            }
            sx={{
              maxWidth: "48%",
              width: "300px",
              height: "54px",
            }}
          >
            Cargar Documento
          </Button>
        </Stack>
      )}
      {selectedPage === 2 && (
        <Button
          className="animate"
          onClick={() => {
            setSelectedPage(2);
            setCommonComponents({
              showNewFolderModal: true,
              currentDocument: null,
            });
          }}
          variant="contained"
          startIcon={
            <FontAwesomeIcon icon={faPlus} style={{ fontSize: "0.99rem" }} />
          }
          sx={{
            position: "absolute",
            right: "48px",
            top: "24px",
            width: "300px",
            height: "54px",
          }}
        >
          Nueva Carpeta
        </Button>
      )}

      {documents && selectedPage === 1 && (
        <Box className={`documents-manager-wrapper animate`} mt={9}>
          <DocumentsManagerTable documents={documents} />
        </Box>
      )}

      {documents && selectedPage === 2 && (
        <Box className={`documents-manager-wrapper animate`} mt={9}>
          <FoldersPage />
        </Box>
      )}
    </div>
  );
};

export default DocumentsManager;
