import React, { useState } from "react";
import { useEffect } from "react";

import AlarmTable from "../../components/Tables/Alarm/AlarmTable.component";

import "./Alarms.styles.css";

import DashboardSectionTitle from "../Dashboard/DashboardSectionTitle/DashboardSectionTitle";
import Select from "../../uiComponents/Select/Select";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { useAtomValue } from "jotai";
import { documentsAtom } from "../../jotai/documents.jotai";

export const filterOptions = [
  {
    value: "vencidos_por_vencer",
    label: "Vencidos y por vencer (+ - 7 días)",
  },
  { value: "vencidos", label: "Vencidos" },
  { value: "sin_vencer", label: "Sin vencer" },
  { value: "todos", label: "Todos" },
  { value: "hoy", label: "Vencimientos de hoy" },
  { value: "proximos_7_dias", label: "Vencimientos de los próximos 7 días" },
  {
    value: "proximos_30_dias",
    label: "Vencimientos de los próximos 30 días",
  },
];

const Alarms: React.FC = () => {
  const { documents } = useAtomValue(documentsAtom);
  const [showTable, setShowTable] = useState<boolean>(false);
  const [alarmDocuments, setAlarmDocuments] = useState<Document[]>([]);

  const FormattAlarmDocuments = (docs: any) => {
    const prevDocuments = [...docs];

    const filteredDocuments = prevDocuments.filter((doc) => {
      return doc.expirationDate;
    });

    const today = Date.now();

    const documentsWithCalculatedDays = filteredDocuments.map((doc) => {
      let expDate = doc.expirationDate;
      if (typeof expDate === "string") {
        expDate = parseInt(expDate);
      }
      const daysToExpiration = expDate - today;

      return { ...doc, daysToExpiration };
    });

    setAlarmDocuments(documentsWithCalculatedDays);

    setShowTable(true);
  };

  const handleSelectChange = (opt: string) => {
    if (documents && documents.length) {
      const today = Date.now();

      const filteredDocuments = documents.filter((doc: any) => {
        let expDate = doc.expirationDate;
        if (typeof expDate === "string") {
          expDate = parseInt(expDate);
        }
        const daysToExpiration = Math.floor(
          (expDate - today) / (1000 * 60 * 60 * 24)
        ); // Convert to days

        switch (opt) {
          case "vencidos_por_vencer":
            return daysToExpiration <= 7 && daysToExpiration >= -7;
          case "vencidos":
            return daysToExpiration < 0;
          case "sin_vencer":
            return daysToExpiration >= 0;
          case "hoy":
            return daysToExpiration === 0;
          case "proximos_7_dias":
            return daysToExpiration > 0 && daysToExpiration <= 7;
          case "proximos_30_dias":
            return daysToExpiration > 0 && daysToExpiration <= 30;
          case "todos":
          default:
            return true;
        }
      });

      FormattAlarmDocuments(filteredDocuments);
    }
  };

  useEffect(() => {
    if (documents && documents.length) {
      handleSelectChange(filterOptions[0].value);
    }
  }, [documents]);

  return (
    <Box className="animate" mt="-55px">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box />
        <Box sx={{ pr: "0.5rem" }}>
          <Select
            style={{ width: "350px", zIndex: 1000 }}
            options={filterOptions}
            defaultValue={filterOptions[0]}
            onChange={(option) => handleSelectChange(option.value)}
          />
        </Box>
      </Box>

      {/* <AlarmTable documents={documents} hideHeader /> */}
      <Box mt={9}>{showTable && <AlarmTable documents={alarmDocuments} />}</Box>
    </Box>
  );
};

export default Alarms;
