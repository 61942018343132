export type DocumentDependencyType =
  | "contain all"
  | "contain any"
  | "exact"
  | "exclude";

export const DocumentdependencyTypes: DocumentDependencyType[] = [
  "contain all",
  "contain any",
  "exact",
  "exclude",
];

export type ParagraphType =
  | "text"
  | "sameParagraph"
  | "newParagraph"
  | "bullet"
  | "title"
  | "subtitle"
  | "list"
  | "signature"
  | "rightAlignedText"
  | "centeredText";

type ParagraphOption = {
  value: ParagraphType;
  label: string;
};

export const ParagraphOptions: ParagraphOption[] = [
  { value: "text", label: "Texto en siguiente linea" },
  { value: "newParagraph", label: "Párrafo nuevo" },
  { value: "sameParagraph", label: "Texto en el mismo párrafo" },
  { value: "bullet", label: "Bullet Point" },
  { value: "title", label: "Título" },
  { value: "subtitle", label: "Subtítulo" },
  { value: "list", label: "Lista" },
  { value: "signature", label: "Firma (agrega más margen)" },
  { value: "rightAlignedText", label: "Texto alineado a la derecha" },
  { value: "centeredText", label: "Texto centrado" },
];

export type DocumentDetails = {
  companyId: string;
  description: string | null;
  documentId: string;
  documentType: string;
  fileName: string;
  dateCreated: string;
  format: string;
};

export type Document = {
  companyId: string;
  documentId: string;
  companyName: string;
  dataPath: string;
  documentName: string;
  documentName1: string;
  documentName2: string;
  generatorVersion: string;
  lastUpdated: Date;
  removeLogo: boolean;
  sheetId: string;
  fileName: string;
  filePath: string;
  format: string;
  description: string;
  [key: string]: any;
};
