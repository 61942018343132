import { Box, Typography } from "@mui/material";

type FolderProps = {
  name: string;
  onClick: () => void;
};

export const Folder: React.FC<FolderProps> = ({ name, onClick }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        pl: "20px",
        minHeight: "50px",
        borderRadius: "9px",
        gap: "10px",
        cursor: "pointer",
      }}
      className="folder-modal-folder b-secondary b-secondary-hover"
      onClick={onClick}
    >
      <i className="far fa-folder-open folder-modal-folder-icon"></i>{" "}
      <Typography fontSize={"15px"}>{name}</Typography>
    </Box>
  );
};
