import { atom, getDefaultStore } from "jotai";

const store = getDefaultStore();

type ConfirmationModalType = {
  disclaimer?: string | null;
  message: string;
  subMessage: string;
  onClose?: () => void;
  onConfirm?: () => void;
  loading?: boolean;
  fileName?: string | null;
  show: boolean;
};

export const initialConfirmationModalState: ConfirmationModalType = {
  message: "",
  subMessage: "",
  show: false,
};

export const confirmationModalAtom = atom<ConfirmationModalType>(
  initialConfirmationModalState
);

export const setConfirmationModalAtom = (params: ConfirmationModalType) => {
  store.set(confirmationModalAtom, {
    ...initialConfirmationModalState,
    ...params,
  });
};
