import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { setLoading } from "../../../Utils/modalMethods";
import AndesModal from "../../../uiComponents/AndesModal/AndesModal";
import { colorPallette } from "../../../Common/theme";
import AutomaticForm, { Field } from "../../../uiComponents/Form/AutomaticForm";

type EditUserModalProps = {
  user: any;
  onClose: () => void;
  onSave: (updatedUser: any) => void;
};

const companyOptions = [
  { value: "12411", label: "Pessah Propiedades" },
  { value: "12412", label: "Exequiel Petagna Compromiso Inmobiliario" },
  { value: "12410", label: "Deinmobiliarios" },
  { value: "11110", label: "Rodrigo Jamardo" },
  { value: "11111", label: "Amengor Perovanis Bienes Raices" },
  { value: "11112", label: "Aguilar Bugeau - Valor Inmobiliario" },
  { value: "11113", label: "C.M. Ciarallo" },
  { value: "11114", label: "Diego Annacondia Propiedades" },
  { value: "11115", label: "Fernando Caro Fretes" },
  { value: "11116", label: "Tomás Oubiña Propiedades" },
  { value: "11117", label: "Emiliano Escola Servicios Inmobiliarios" },
  { value: "11118", label: "Valdez Propiedades" },
  { value: "11119", label: "Martha Monsalvo Propiedades" },
  { value: "11120", label: "Diego Nieto Calidad Inmobiliaria" },
  { value: "11121", label: "Hugo Moreno Saucedo Familia Inmobiliaria" },
  { value: "11122", label: "Sebastián Esquivel Propiedades" },
  { value: "11123", label: "Di Blasi Propiedades" },
  { value: "11124", label: "Rao Propiedades" },
  { value: "11125", label: "Marcela Genta Operaciones Inmobiliarias" },
  { value: "11126", label: "Victor Bratina Inmuebles" },
  {
    value: "11127",
    label: "Duran Inmuebles / Duran Arancibia Inmuebles (Oficial)",
  },
  { value: "11128", label: "Damian Bergenfeld" },
  { value: "11129", label: "Malek Inmobiliaria" },
  { value: "11130", label: "Valencia Neira Real Estate" },
  { value: "11131", label: "Andrea Fernandez Real Estate" },
  { value: "11132", label: "Marcos Del Giorgio Identidad Inmobiliaria" },
  { value: "11133", label: "Walter Colonnese" },
  { value: "11134", label: "Olivares Salinas Vínculo Inmobiliario" },
  { value: "11135", label: "Esperón Propiedades" },
  { value: "11136", label: "Urbanuz Soluciones Inmobiliarias" },
  { value: "11137", label: "Christian Jakubowicz Bienes Raíces" },
  { value: "11138", label: "Manuel Abad Propiedades" },
  { value: "11139", label: "Dimitri Propiedades" },
  { value: "11140", label: "Nicolas Garcia Calabuig Propiedades" },
  { value: "11141", label: "Vicente Castex Laprida Propiedades + Inversiones" },
  { value: "11142", label: "Bolajuzon Servicios Inmobiliarios" },
  { value: "11143", label: "Miguel Duffy" },
  { value: "11144", label: "Evelyn Quintar Bienes Raíces" },
  { value: "11145", label: "ADD Servicios Inmobiliarios S.R.L." },
  { value: "11146", label: "Rapaport Bienes Raíces" },
  { value: "11147", label: "Mercedes Paz Propiedades" },
  { value: "11148", label: "Fiedotin Propiedades" },
  { value: "11149", label: "Iacopi Propiedades" },
  { value: "11150", label: "Diego Atterio Propiedades" },
  { value: "11151", label: "Miani Servicios Inmobiliarios" },
  { value: "11152", label: "Marcela Capara" },
  { value: "11153", label: "Macarena Santillan" },
  { value: "11154", label: "Malvido Propiedades" },
].sort((a, b) => a.label.localeCompare(b.label));

const roleOptions = [
  { value: "admin", label: "Admin" },
  { value: "user", label: "User" },
];

const documentShareOptions = [
  { value: "true", label: "Todos los documentos de la empresa" },
  { value: "false", label: "Solo los documentos del usuario" },
];

const EditUserModalDeIn: React.FC<EditUserModalProps> = ({
  user,
  onClose,
  onSave,
}) => {
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      companyId: user?.companyId,
      role: user?.role,
      documentShare: user?.documentShare ? "true" : "false",
    },
  });

  useEffect(() => {
    setValue("role", user?.role);
    setValue("documentShare", user?.documentShare ? "true" : "false");
    setValue("companyId", user?.companyId);
  }, [user, setValue]);

  const handleSave = (data: any) => {
    setLoading(true);
    onSave({
      ...user,
      companyId: data.companyId,
      role: data.role,
      documentShare: data.documentShare === "true",
    });
    setLoading(false);
  };

  const fields: Field[] = [
    {
      name: "companyId",
      label: "Empresa",
      type: "options",
      required: true,
      options: companyOptions,
    },
    {
      name: "role",
      label: "Rol",
      type: "options",
      required: true,
      options: roleOptions,
    },
    {
      name: "documentShare",
      label: "Acceso a",
      type: "options",
      required: true,
      options: documentShareOptions,
    },
  ];

  return (
    <AndesModal
      handleClose={onClose}
      message="Editar usuario"
      handleSubmit={handleSubmit(handleSave)}
    >
      <Box
        sx={{
          width: "100%",
          textAlign: "left",
          my: 2,
          fontSize: "1rem",
        }}
      >
        <FontAwesomeIcon
          icon={faUserAlt}
          style={{ color: colorPallette.darkSecondary, marginRight: 8 }}
        />
        {user?.name}
      </Box>
      <AutomaticForm fields={fields} control={control} />
    </AndesModal>
  );
};

export default EditUserModalDeIn;
