import React from "react";
import "./styles.css";

type Props = {
  color?: any;
  label?: any;
  icon?: any;
  onClick?: any;
  className?: any;
  type?: any;
  style?: any;
};

const IconButton: React.FC<Props> = ({
  color,
  label,
  icon,
  onClick,
  className,
  type,
  style,
}) => {
  const buttonColor = () => {
    if (color === "primary") {
      return "b-primary b-primary-hover";
    }

    if (color === "secondary") {
      return "b-secondary b-secondary-hover";
    }
    if (color === "transparent") {
      return "button-transparent";
    }
    if (color === "tertiary") {
      return "b-tertiary";
    }
    if (color === "red") {
      return "b-red b-red-hover";
    }
    return "b-primary b-primary-hover";
  };

  return (
    <button
      style={style}
      className={`icon-button ${buttonColor()} ${className}`}
      onClick={onClick}
      type={type && type}
    >
      <i className={`${icon} icon-button-icon`}></i>
      {label}
    </button>
  );
};

export default IconButton;
