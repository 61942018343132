/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { Box, Button, Stack } from "@mui/material";
import FormSections from "./FormSections";
import { setAppError } from "../../Utils/modalMethods";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle, faPlus } from "@fortawesome/free-solid-svg-icons";
import { colorPallette } from "../../Common/theme";
import InternalGeneratorTaskBar from "./InternalGeneratorTaskBar";
import QuestionGeneratorMemo from "./QuestionGeneratorMemo";
import { useSelector } from "react-redux";
import { setCommonComponents } from "../../jotai/commonComponentAtom.jotai";

const InternalGenerator = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [disabledSections, setDisabledSections] = useState<string[]>([]);
  const [selectedSections, setSelectedSections] = useState<number[]>([]);

  const resetFilters = () => {
    setSelectedSections([]);
    setDisabledSections([]);
  };

  const { control, handleSubmit, watch, getValues, trigger } = useForm<any>({
    defaultValues: JSON.parse(
      localStorage.getItem("generator-form-data") || "[]"
    ) || {
      questions: [{}],
    },
    shouldFocusError: true,
  });

  const { fields, append, remove, insert } = useFieldArray({
    name: "questions",
    control,
  });

  const areIdsUnique = (array: any[]): boolean => {
    const idTracker: { [key: number]: boolean } = {};
    for (const obj of array) {
      if (idTracker[obj.id]) {
        return false;
      }
      idTracker[obj.id] = true;
    }
    return true;
  };

  const sectionFormHookData = useForm<any>({
    defaultValues: JSON.parse(
      localStorage.getItem("generator-form-sections") || "{}"
    ),
  });

  const onSubmit = (data: any, callback?: any, hideSuccess?: boolean) => {
    if (data.questions.length === 0) {
      return setAppError(
        "Formulario Incompleto",
        "Debe agregar al menos una pregunta"
      );
    }

    if (!areIdsUnique(data.questions)) {
      return setAppError(
        "Campos identificadores duplicados",
        "Los campos identificadores  de las preguntas deben ser únicos"
      );
    }

    localStorage.setItem("generator-form-data", JSON.stringify(data));

    const sectionKeys = Object.keys(sectionFormHookData.getValues());
    const sectionValues = sectionFormHookData.getValues();
    sectionKeys.forEach((key) => {
      if (sectionValues[key] === undefined) {
        sectionValues[key] = `Sección ${key}`;
      }
    });

    localStorage.setItem(
      "generator-form-sections",
      JSON.stringify(sectionValues)
    );
    if (!hideSuccess)
      setCommonComponents({
        successModal: {
          show: true,
          message: "Guardado Correctamente",
          submessage: "El formulario ha sido guardado",
          disclaimer: "",
        },
      });

    if (callback) callback();
  };

  const onError = () => {
    setAppError(
      "Formulario incompleto",
      "Debe completar todos los campos obligatorios"
    );
  };

  const formWatch = watch();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 74) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const expandedCardId = useSelector(
    (state: any) => state.commonComponents.expandedCardId
  );

  const [isInitialRender, setIsInitialRender] = useState(true);

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      return;
    }
    const data = getValues();
    if (!data || !data.questions || data.questions.length === 0) {
      return;
    }

    onSubmit(data, undefined, true);
  }, [expandedCardId]);

  const handleFilterSection = (section: number) => {
    setSelectedSections((prevSelected) =>
      prevSelected.includes(section)
        ? prevSelected.filter((sec) => sec !== section)
        : [...prevSelected, section]
    );

    const sectionStr = String(section);
    if (disabledSections.includes(sectionStr)) {
      setDisabledSections(disabledSections.filter((sec) => sec !== sectionStr));
    } else {
      setDisabledSections([...disabledSections, sectionStr]);
    }
  };

  return (
    <Box
      display="grid"
      gridTemplateColumns="380px 1fr"
      sx={{
        "& input": {
          color: "#5b55a0",
        },
      }}
    >
      <FormSections
        formWatch={formWatch}
        sectionFormHookData={sectionFormHookData}
        handleFilterSection={handleFilterSection}
        selectedSections={selectedSections}
        resetFilters={resetFilters}
      />
      <Box width={"100%"}>
        <Stack
          minHeight="100vh"
          alignItems="center"
          px={4}
          className="b-tertiary"
          pb="140px"
          position="relative"
        >
          <Box
            position="sticky"
            top={0}
            zIndex={1000}
            left="90%"
            display="flex"
          >
            <Box
              sx={{
                zIndex: 100,
                backgroundColor: colorPallette.secondary,
                fontSize: 14,
                padding: "10px 16px",
                cursor: "pointer",
                transform: "translateX(-100%)",
                transition: "all 0.1s",
                borderBottomLeftRadius: 10,
                "&:hover": {
                  opacity: 0.6,
                },
                ...(isSticky && { position: "sticky", top: 0 }),
              }}
              onClick={() => {
                handleSubmit(onSubmit, onError)();
              }}
            >
              Guardar
            </Box>
          </Box>
          <Stack
            justifyContent="space-between"
            width="100%"
            flexDirection="row"
            px={5}
            pb={5}
            alignItems="center"
            maxWidth={1100}
          >
            <Stack
              direction="row"
              width="100%"
              textAlign="left"
              maxWidth={1000}
              mt={5}
              pl={2}
              fontWeight="Bold"
              fontSize={23}
              alignItems="center"
            >
              <FontAwesomeIcon
                icon={faPlayCircle}
                style={{
                  color: colorPallette.primary,
                  marginRight: 10,
                }}
              />
              <Stack sx={{ transform: "translateY(-8px)" }}>
                <Box fontWeight={400} fontSize={14} mb={"-2px"}>
                  Editor de Documentos
                </Box>
                Generación de preguntas
              </Stack>
            </Stack>
          </Stack>

          {fields.map((field: any, index: number) => {
            const section = watch(`questions.${index}.section`);
            const isFiltered =
              disabledSections.length > 0 &&
              !disabledSections.includes(String(section));

            return (
              <Box
                key={field.id}
                sx={{
                  opacity: isFiltered ? 0 : 1,
                  position: isFiltered ? "absolute" : "auto",
                  zIndex: isFiltered ? 10 : 50,
                  visibility: isFiltered ? "hidden" : "visible",
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <QuestionGeneratorMemo
                  control={control}
                  id={index}
                  key={field.id}
                  removeQuestion={remove}
                  index={index}
                  insert={insert}
                  remove={remove}
                  field={field}
                  getValues={getValues}
                  trigger={trigger}
                />
              </Box>
            );
          })}
          <Stack>
            {fields.length < 1 && (
              <Button
                onClick={() => {
                  append({
                    question: "",
                    type: "question",
                    dependencies: [],
                    section: "1",
                    info: "",
                    id: "",
                  });
                }}
                sx={{ mb: 2, width: 300, color: "#5b55a0" }}
              >
                <FontAwesomeIcon icon={faPlus} className="primary mr1" />
                Agregar una pregunta
              </Button>
            )}
          </Stack>
        </Stack>
      </Box>

      <InternalGeneratorTaskBar
        onSave={(callback?: any) =>
          handleSubmit((data) => onSubmit(data, callback), onError)()
        }
      />
    </Box>
  );
};

export default InternalGenerator;
