import { useHistory } from "react-router-dom";
import Button from "../../uiComponents/Button/Button";
import { Stack } from "@mui/material";
import { gradients } from "../../Common/theme";
import { useAuth0 } from "@auth0/auth0-react";

const SupportConfig = () => {
  let history = useHistory();
  const { loginWithRedirect } = useAuth0();

  return (
    <Stack
      sx={{
        background: gradients.dark,
        display: "flex",
        justifyContent: "center",
        position: "relative",
        pb: { xs: 10, sm: 20, md: 15, lg: 10 },
        height: "100vh",
      }}
    >
      <h1 className="margin-v-l">
        ¡Gracias por Registrarte en
        <span className="primary"> Andes Docs!</span>
      </h1>
      <p className="margin-v">Solo falta un paso mas:</p>

      <p className="margin-v">
        Por favor contactate con soporte para ser asignado a tu empresa o a una
        prueba gratuita.
      </p>
      <Button
        label="Contactar Soporte"
        onClick={() => {
          history.push("/contacto");
        }}
        color="secondary"
        center
      />
      <Button
        label="Reintentar"
        onClick={() => {
          loginWithRedirect();
        }}
        color="secondary"
        center
      />
    </Stack>
  );
};

export default SupportConfig;
