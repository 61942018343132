import React, { useEffect, useState } from "react";
import "../styles.css";
import "./styles.css";
import numbersToLetters from "../../../Utils/numbersToLetters";
import thousandSeparated from "../../../Utils/thousandSeparated";
import Date from "./Date";

const currency = [
  "Pesos Argentinos",
  "Dólares Estadounidenses",
  "Dólares Estadounidenses billete",
  "Euros",
  "Libras Esterlinas",
  "Bitcoin",
  "Ethereum",
];

type MoneyAndDatesProps = {
  question: any;
  onChange: any;
  currentAnswer: any;
  sectionId: any;
  currentSectionAnswers: any;
  currentAnswerObject: any;
};

const MoneyAndDates: React.FC<MoneyAndDatesProps> = ({
  question,
  onChange,
  currentAnswer,
  sectionId,
  currentSectionAnswers,
  currentAnswerObject,
}) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [currentCurrency, setCurrentCurrency] = useState("");
  const [currentNumber, setCurrentNumber] = useState("0");
  const [currentFull, setCurrentFull] = useState("");
  const [currentDecimals, setCurrentDecimals] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const answerNormalizer = (value: any) => {
    let valueNormalized = typeof value === "string" ? value.split("") : [];
    let newValue = valueNormalized.filter((val) => /[\d|]/.test(val)).join("");
    return newValue;
  };

  const addLetters = (
    fullNumber: any,
    decimalPart: any,
    thisCurrency: string = ""
  ) => {
    if (fullNumber.length < 1) {
      return null;
    }

    const parsedNum = parseFloat(`${fullNumber}${decimalPart}`);
    const letters = numbersToLetters(parsedNum);
    const decimalWithComma = decimalPart.replace(".", ",");
    const fullNum = parseInt(fullNumber);
    const withThousandsFull = thousandSeparated(fullNum);
    const numberWithComma = `${withThousandsFull}${decimalWithComma}`;

    let final = "";
    if (thisCurrency === "Pesos Argentinos") {
      final = `${letters} ($ ${numberWithComma})`;
    } else if (thisCurrency === "Dólares Estadounidenses") {
      final = `${letters} (USD ${numberWithComma})`;
    } else if (thisCurrency === "Dólares Estadounidenses billete") {
      final = `USD ${numberWithComma}`;
    } else {
      final = `${letters} (${numberWithComma})`;
    }

    if (fullNumber.length > 9) {
      final = `$${numberWithComma}`;
    }
    if (
      (fullNumber.length > 9 && thisCurrency === "Dólares Estadounidenses") ||
      thisCurrency === "Dólares Estadounidenses billete"
    ) {
      final = `USD ${numberWithComma}`;
    }
    return final;
  };

  useEffect(() => {
    if (
      currentAnswer &&
      answerNormalizer(currentAnswer) !== "" &&
      answerNormalizer(currentAnswer) !== " "
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }

    if (
      currentAnswerObject &&
      currentAnswerObject.currency &&
      currentAnswerObject.currency !== ""
    ) {
      setCurrentCurrency(currentAnswerObject.currency);
    }

    if (
      currentAnswerObject &&
      currentAnswerObject.currentFull &&
      currentAnswerObject.currentFull !== ""
    ) {
      setCurrentFull(currentAnswerObject.currentFull);
    }

    if (
      currentAnswerObject &&
      currentAnswerObject.currentDecimals &&
      currentAnswerObject.currentDecimals !== ""
    ) {
      setCurrentDecimals(currentAnswerObject.currentDecimals);
    }
  }, [currentAnswer, currentAnswerObject]);

  const onChangeDateInput = (newDate: any, type: any) => {
    if (type === "startDate") {
      setStartDate(newDate);
      onChange({
        [sectionId]: {
          ...currentSectionAnswers,
          [question.id]: {
            question: question.question,
            answer: `${currentCurrency} ${addLetters(
              `${currentFull}`,
              `${currentDecimals}`
            )} entre el ${newDate} y el ${endDate}`,
            currency: currentCurrency,
            currentFull: currentFull,
            currentDecimals: currentDecimals,
            startDate: newDate,
            endDate,
          },
        },
      });
    }
    if (type === "endDate") {
      setEndDate(newDate);
      onChange({
        [sectionId]: {
          ...currentSectionAnswers,
          [question.id]: {
            question: question.question,
            answer: `${currentCurrency} ${addLetters(
              `${currentFull}`,
              `${currentDecimals}`
            )} entre el ${startDate} y el ${newDate}`,
            currency: currentCurrency,
            currentFull: currentFull,
            currentDecimals: currentDecimals,
            startDate,
            endDate: newDate,
          },
        },
      });
    }
  };

  return (
    <div className="m-dates-wrapper">
      <div className="question-format-form question-format-money-div">
        <input
          required
          type="text"
          value={answerNormalizer(currentFull)}
          className="question-format-input-money"
          placeholder="Numero Entero"
          onChange={(e) => {
            setCurrentCurrency("");
            let valueNormalized =
              typeof e.target.value === "string"
                ? e.target.value.split("")
                : [];
            let newValue = valueNormalized
              .filter((val) => /[\d]/.test(val))
              .join("");
            if (newValue.length > 12) {
              const oldValue = newValue;
              newValue = currentFull;
            }
            setCurrentFull(newValue);
            setCurrentNumber(`${newValue}${currentDecimals}`);
            onChange({
              [sectionId]: {
                ...currentSectionAnswers,
                [question.id]: {
                  question: question.question,
                  answer: `${addLetters(
                    `${newValue}`,
                    `${currentDecimals}`
                  )} entre el ${startDate} y el ${endDate}`,
                  currency: "",
                  currentFull: newValue,
                  currentDecimals: currentDecimals,
                  startDate,
                  endDate,
                },
              },
            });
          }}
        ></input>
        <input
          className="question-format-input-decimals"
          placeholder="Centavos"
          value={answerNormalizer(currentDecimals)}
          onChange={(e) => {
            setCurrentCurrency("");
            let valueNormalized =
              typeof e.target.value === "string"
                ? e.target.value.split("")
                : [];
            let newValue = valueNormalized
              .filter((val) => /[\d]/.test(val))
              .join("");
            if (newValue.length > 0) {
              const oldValue = newValue;
              newValue = `.${newValue}`;
            }
            if (newValue.length > 3) {
              const oldValue = newValue;
              newValue = currentDecimals;
            }
            setCurrentDecimals(newValue);
            setCurrentNumber(`${currentFull}${newValue}`);
            onChange({
              [sectionId]: {
                ...currentSectionAnswers,
                [question.id]: {
                  question: question.question,
                  answer: `${addLetters(
                    `${currentFull}`,
                    `${newValue}`
                  )} entre el ${startDate} y el ${endDate}`,
                  currency: "",
                  currentFull: currentFull,
                  currentDecimals: newValue,
                  startDate,
                  endDate,
                },
              },
            });
          }}
        ></input>
        <select
          disabled={isDisabled}
          required
          value={currentCurrency}
          className="question-format-input-currency"
          onChange={(e) => {
            setCurrentCurrency(e.target.value || "");
            const withLetters = addLetters(
              `${currentFull}`,
              `${currentDecimals}`,
              e.target.value
            );
            onChange({
              [sectionId]: {
                ...currentSectionAnswers,
                [question.id]: {
                  ...currentAnswerObject,
                  answer: `${e.target.value} ${withLetters} entre el ${startDate} y el ${endDate}`,
                  currency: e.target.value,
                  startDate,
                  endDate,
                },
              },
            });
          }}
        >
          <option value="">Moneda</option>
          {currency.map((e) => {
            return <option value={e}>{e}</option>;
          })}
        </select>
      </div>
      <Date
        type="startDate"
        onChange={(e: any, b: any) => {
          onChangeDateInput(e, b);
        }}
        label="Fecha de inicio:"
      />
      <Date
        type="endDate"
        onChange={(e: any, b: any) => {
          onChangeDateInput(e, b);
        }}
        label="Fecha de finalización:"
      />
    </div>
  );
};

export default MoneyAndDates;
