import React from "react";
import FormSelect, { Option } from "./FormSelect";
import FormInput from "./FormInput";
import FormNumber from "./FormNumber";
import { Stack } from "@mui/material";
import FormDate from "./FormDate";
import FormDatePlain from "./FormDatePlain";

export type Field = {
  name: string;
  label: string;
  type?:
    | "number"
    | "options"
    | "checkbox"
    | "text"
    | "email"
    | "date"
    | "plainDate";
  required?: boolean;
  options?: Option[];
  checkboxOptions?: string[];
  validation?: any;
};

type Props = {
  control: any;
  fields: Field[];
};

const AutomaticForm: React.FC<Props> = ({ control, fields }) => {
  // test
  return (
    <Stack width="100%">
      {fields.map((field: Field) => {
        if (field.type === "options" && field.options) {
          return (
            <FormSelect
              key={field.name}
              options={field.options}
              control={control}
              label={field.label}
              name={field.name}
              required={field.required || false}
              //   isInvalid={errors && errors[field.name]}
            />
          );
        }
        // if (field.type === "checkbox" && field.options) {
        //   return (
        //     <FormCheckbox
        //       key={field.name}
        //       options={field.options}
        //       control={control}
        //       name={field.name}
        //       required={field.required || false}
        //       label={field.label}
        //     />
        //   );
        // }
        if (field.type === "number" && field.options) {
          return (
            <FormNumber
              key={field.name}
              control={control}
              label={field.label}
              name={field.name}
              required={field.required || false}
              //   isInvalid={errors && errors[field.name]}
            />
          );
        }
        if (field.type === "date") {
          return (
            <FormDate
              key={field.name}
              control={control}
              label={field.label}
              name={field.name}
              required={field.required || false}
              //   isInvalid={errors && errors[field.name]}
            />
          );
        }
        if (field.type === "plainDate") {
          return (
            <FormDatePlain
              key={field.name}
              control={control}
              label={field.label}
              name={field.name}
              required={field.required || false}
              //   isInvalid={errors && errors[field.name]}
            />
          );
        }

        return (
          <FormInput
            control={control}
            key={field.name}
            label={field.label}
            name={field.name}
            required={field.required || false}
            rules={field.validation}
          />
        );
      })}
    </Stack>
  );
};

export default AutomaticForm;
