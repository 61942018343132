import { Box, FormControlLabel, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import { processDependencies, processText } from "../../Utils/TextProcessor";
import { colorPallette } from "../../Common/theme";
import { useSelector } from "react-redux";

// esta funcion es necesaria por que cuando tenes un SameParagraph
// tenes que volver para atras y agregarlo al elemento anterior
// es una funcion dificil de entender, pero funciona
// crea un array de parrafos que son del tipo sameParagraph
// y despues se agrega el indice del ultimo parrafo que no sea sameParagraph
const createSameParagraphArray = (documentData: any) => {
  let lastIndex = 1;
  return documentData?.reduce((acc: any[], paragraph: any, index: number) => {
    if (paragraph.type === "sameParagraph") {
      acc.push({ ...paragraph, paragraphIndex: lastIndex });
    } else {
      lastIndex = index;
    }
    return acc;
  }, []);
};

const PreviewParagraph: React.FC<any> = ({ index, children }) => {
  return (
    <Box
      onClick={() => {
        // This makes form scroll into view when selected
        const paragraph = document.querySelectorAll(
          `.paragraph_ref_${index}`
        ) as any;
        if (paragraph[0] && paragraph[1]) {
          paragraph[0].scrollIntoView({ behavior: "smooth" });
          paragraph[1].style.backgroundColor = "#31377a";
          paragraph[1].style.transform = "scale(1.04)";
          setTimeout(() => {
            paragraph[1].style.backgroundColor = "#ffffff";
            paragraph[1].style.transform = "scale(1)";
          }, 700);
        }
      }}
      sx={{
        "&:hover": {
          transform: "scale(1.005)",
          backgroundColor: "#f6f9fc",
        },
        cursor: "pointer",
        transition: "all 0.2s ease-in-out",
        borderRadius: "8px",
        px: "20px",
        py: "0px",
        mb: 0,
        mt: 0,
      }}
    >
      {children}
    </Box>
  );
};

const DocumentPreview: React.FC = () => {
  const [sameParagraphs, setSameParagraphs] = useState<any>([]);
  const [applyDeps, setApplyDeps] = useState(true);

  const documentData = useSelector(
    (state: any) => state.commonComponents.formWatch
  );

  useEffect(() => {
    setSameParagraphs(createSameParagraphArray(documentData));
  }, [documentData]);

  const renderSameParagraph = (index: number) => {
    const sameParagraphsToRender = sameParagraphs
      .filter((p: any) => p.paragraphIndex === index)
      .filter((p: any) => {
        let shouldRender = true;
        if (p.dependencies) {
          shouldRender = processDependencies(p.dependencies);
        }
        return shouldRender;
      });

    return sameParagraphsToRender.map((p: any) => <>{processText(p?.text)}</>);
  };

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={applyDeps}
            onChange={() => setApplyDeps((prev) => !prev)}
            color="primary"
          />
        }
        sx={{
          position: "absolute",
          top: -5,
          right: 90,
          bgcolor: colorPallette.secondary,
          px: 2,
          borderRadius: "8px",
          transform: "scale(0.8)",
        }}
        label={"Aplicar Dependencias"}
      />
      <Box
        width="100%"
        padding="25px 15px 25px 15px"
        marginBottom="40px"
        borderRadius="0px"
        boxShadow="3px 3px 4px 0px rgba(0, 0, 0, 0.13)"
        minHeight="1000px"
        maxWidth={"1200px"}
        sx={{
          backgroundColor: "#ffffff",
          color: colorPallette.dark,
          fontFamily: "Arial, Helvetica, sans-serif",
          "& li": {
            marginLeft: "20px",
            marginY: "10px",
          },
        }}
      >
        {documentData &&
          documentData.map((paragraph: any, index: number) => {
            let shouldRender = true;
            if (paragraph.dependencies && applyDeps) {
              shouldRender = processDependencies(paragraph.dependencies);
            }

            if (!shouldRender) return <></>;
            if (paragraph.type === "sameParagraph") return <></>;

            if (paragraph.type === "title")
              return (
                <PreviewParagraph index={index}>
                  <Box
                    sx={{
                      textAlign: "center",
                      fontSize: "14px",
                      color: "black",
                      fontWeight: "bold",
                      textDecoration: "underline",
                      marginBottom: "20px",
                      marginTop: "0",
                      lineHeight: "2",
                    }}
                  >
                    {paragraph?.text && processText(paragraph?.text)}
                    {renderSameParagraph(index)}
                  </Box>
                </PreviewParagraph>
              );
            if (paragraph.type === "centeredText")
              return (
                <PreviewParagraph index={index}>
                  <Box
                    sx={{
                      textAlign: "center",
                      fontSize: "14px",
                      color: "black",
                      marginBottom: "0px",
                      lineHeight: "2",
                    }}
                  >
                    {paragraph?.text && processText(paragraph?.text)}
                    {renderSameParagraph(index)}
                  </Box>
                </PreviewParagraph>
              );
            if (paragraph.type === "subtitle")
              return (
                <PreviewParagraph index={index}>
                  <Box
                    sx={{
                      fontSize: "14px",
                      color: "black",
                      fontWeight: "bold",
                      marginBottom: "0",
                      marginTop: 3,
                      lineHeight: "2",
                      textAlign: "justify",
                    }}
                  >
                    {paragraph?.text && processText(paragraph?.text)}
                    {renderSameParagraph(index)}
                  </Box>
                </PreviewParagraph>
              );
            if (paragraph.type === "bullet")
              return (
                <PreviewParagraph index={index}>
                  <Box
                    sx={{
                      textAlign: "justify",
                      fontSize: "14px",
                      color: "black",
                      marginBottom: "0px",
                      lineHeight: "2",
                      mt: 0,
                      ml: 2,
                    }}
                    component="ul"
                  >
                    <li style={{ margin: 0, padding: 0 }}>
                      {paragraph?.text && processText(paragraph?.text)}
                      {renderSameParagraph(index)}
                    </li>
                  </Box>
                </PreviewParagraph>
              );

            if (paragraph.type === "rightAlignedText")
              return (
                <PreviewParagraph index={index}>
                  <Box
                    sx={{
                      textAlign: "right",
                      fontSize: "14px",
                      color: "black",
                      marginBottom: "0px",
                      lineHeight: "2",
                    }}
                    component="ul"
                  >
                    {paragraph?.text && processText(paragraph?.text)}
                    {renderSameParagraph(index)}
                  </Box>
                </PreviewParagraph>
              );
            if (paragraph.type === "newParagraph")
              return (
                <PreviewParagraph index={index}>
                  <Box
                    sx={{
                      mt: 3,
                      textAlign: "justify",
                      fontSize: "14px",
                      color: "black",
                      marginBottom: "0px",
                      lineHeight: "2",
                    }}
                  >
                    {paragraph?.text && processText(paragraph?.text)}
                    {renderSameParagraph(index)}
                  </Box>
                </PreviewParagraph>
              );
            if (paragraph.type === "signature")
              return (
                <PreviewParagraph index={index}>
                  <Box
                    sx={{
                      mt: 8,
                      textAlign: "justify",
                      fontSize: "14px",
                      color: "black",
                      marginBottom: "0px",
                      lineHeight: "2",
                    }}
                  >
                    {paragraph?.text && processText(paragraph?.text)}
                  </Box>
                </PreviewParagraph>
              );

            return (
              <PreviewParagraph index={index}>
                <Box
                  sx={{
                    textAlign: "justify",
                    fontSize: "14px",
                    color: "black",
                    marginBottom: "0px",
                    lineHeight: "2",
                  }}
                >
                  {paragraph?.text && processText(paragraph?.text)}
                  {renderSameParagraph(index)}
                </Box>
              </PreviewParagraph>
            );
          })}
      </Box>
    </>
  );
};

export default DocumentPreview;
