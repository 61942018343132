import store from "../redux/store";
import { setModal } from "../redux/modal/modal.actions";
import {
  resetCommonComponent,
  setCommonComponents,
} from "../jotai/commonComponentAtom.jotai";

export const closeErrorModal = () => {
  resetCommonComponent();
};

export const setAppError = (
  message?: string,
  submessage?: string,
  disclaimer?: string
) => {
  setCommonComponents({
    errorModal: {
      show: true,
      message: message || "Error",
      submessage:
        submessage || "Hubo un error de sistema. Intentelo más tarde.",
      disclaimer:
        disclaimer || "Si tiene un problema persistente, contacte con soporte.",
    },
  });
};

export const setLoading = (bool = false) => {
  store.dispatch(
    setModal({
      loadingModal: {
        show: bool,
      },
    })
  );
};
