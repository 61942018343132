import React, { useEffect, useRef, useState } from "react";
import "./DocxEditorMain.styles.css";
import {
  DocumentEditorContainerComponent,
  Toolbar,
} from "@syncfusion/ej2-react-documenteditor";
import { useDispatch, useSelector } from "react-redux";
import { downloadWordFromS3andReturn } from "../../Utils/S3Download";
import { setAppError, setLoading } from "../../Utils/modalMethods";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import DocxToolbar from "./DocxToolbar";
import { APIPostFile } from "../../Services/authenticated";
import { setShouldESign } from "../../redux/slices/EditorDataSlice";
import { RootState } from "../../redux/store";
import DocxNotCompatibleMessage from "./DocxNotCompatibleMessage";
import {
  initializeLocalization,
  serviceUrl,
} from "../../Utils/documentEditorConfig";
import { updateDocumentOnAtom } from "../../jotai/documents.jotai";
import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt } from "@fortawesome/free-solid-svg-icons";
import { colorPallette } from "../../Common/theme";
import { setCommonComponents } from "../../jotai/commonComponentAtom.jotai";

initializeLocalization();

DocumentEditorContainerComponent.Inject(Toolbar);

const DocxEditorMain: React.FC = () => {
  let editorObj: DocumentEditorContainerComponent | null = null;
  const wordDocument = useSelector((state: any) => state.editorData.document);
  const { getAccessTokenSilently, user } = useAuth0();
  const history = useHistory();
  const dispatch = useDispatch();
  const showEditor = useRef(false);
  const [isDocumentReady, setIsDocumentReady] = useState(false);
  const [showCompatibilityNotification, setShowCompatibilityNotification] =
    useState(false);
  const reduxUser = useSelector((state: RootState) => state.masterData.user);

  const exitEditor = () => {
    history.push("/dashboard/finished");
  };

  const loadFileFromServer = async (documentData: any) => {
    try {
      setLoading(true);
      const accessToken = await getAccessTokenSilently();
      const file = await downloadWordFromS3andReturn(documentData, accessToken);

      let ajax: XMLHttpRequest = new XMLHttpRequest();
      ajax.open("POST", serviceUrl + "Import", true);
      ajax.onreadystatechange = () => {
        if (ajax.readyState === 4) {
          if (ajax.status === 200 || ajax.status === 304) {
            // open SFDT text in document editor
            editorObj?.documentEditor.open(ajax.responseText);
            if (editorObj?.documentEditor.isDocumentLoaded) {
              showEditor.current = true;
              setIsDocumentReady(true);
              setLoading(false);
              window.scrollTo(0, 100);
            } else {
              exitEditor();
            }
          }
        }
      };
      let formData: FormData = new FormData();
      formData.append("files", file);
      ajax.send(formData);
    } catch {
      setLoading(false);
      exitEditor();
      setAppError();
    } finally {
      setLoading(false);
    }
  };

  const onDownload = () => {
    if (wordDocument && wordDocument.fileName)
      editorObj?.documentEditor.save(wordDocument.fileName, "Docx");
  };

  const updateDocument = async (shouldESign: boolean) => {
    const now = Date.now();
    try {
      setLoading(true);
      const accesToken = await getAccessTokenSilently();
      const blob = await editorObj?.documentEditor.saveAsBlob("Docx");
      if (!blob) {
        setAppError();
        return;
      }

      const file = new File([blob], `${wordDocument.fileName}.docx`, {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });

      const params = {
        data: file,
        ...wordDocument,
        updateDate: now,
      };

      const docRef = await APIPostFile(
        "/doc-ref/save-edited-document",
        accesToken,
        params
      );

      if (docRef.documentSaved && !shouldESign) {
        updateDocumentOnAtom({
          ...params,
          status: "unapproved",
        });

        setTimeout(() => {
          setCommonComponents({
            successModal: {
              show: true,
              message: "Documento guardado exitosamente",
              submessage:
                "Si el documento estaba aprobado, deberá ser aprobado nuevamente",
              disclaimer: "",
            },
          });
        }, 1000);
      } else if (docRef.documentSaved && shouldESign) {
        dispatch(setShouldESign(true));
        setLoading(false);
        history.push("/dashboard/esignature");
      } else {
        setAppError();
      }
    } catch (error) {
      setAppError();
    } finally {
      setLoading(false);
    }
  };

  const userName = reduxUser?.name ? reduxUser.name : user?.name;

  const sendToEsign = async () => {
    await updateDocument(true);
  };

  useEffect(() => {
    if (wordDocument) {
      setTimeout(() => {
        loadFileFromServer(wordDocument);
      });
    }
    if (!wordDocument) {
      exitEditor();
    }
    setTimeout(() => {
      // Check if the code is running on the client side
      if (showEditor.current === false) {
        setShowCompatibilityNotification(true);
      }
    }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wordDocument]);

  return (
    <>
      <div className="docx-loader">
        {!showCompatibilityNotification && (
          <Box>
            <FontAwesomeIcon
              icon={faBolt}
              style={{ marginRight: 12, color: colorPallette.darkSecondary }}
            />
            Procesando tu documento ...
          </Box>
        )}
        {showCompatibilityNotification && <DocxNotCompatibleMessage />}
      </div>
      {wordDocument && isDocumentReady && (
        <DocxToolbar
          document={wordDocument}
          onDownload={onDownload}
          updateDocument={updateDocument}
          sendToEsign={sendToEsign}
        />
      )}
      {!showCompatibilityNotification && (
        <div
          className="docx-ditor-wrapper"
          style={{ opacity: isDocumentReady ? 1 : 0 }}
        >
          <DocumentEditorContainerComponent
            locale="es"
            height="1200px"
            enableToolbar={true}
            ref={(ins) => (editorObj = ins)}
            serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/documenteditor/"
            currentUser={userName || "Usuario Invitado"}
            // id="#docx-editor-andes-docs"
          ></DocumentEditorContainerComponent>
        </div>
      )}
    </>
  );
};

export default DocxEditorMain;
