import React, { useState } from "react";
import { Box, IconButton, Snackbar, Stack, Typography } from "@mui/material";
import { useAtom } from "jotai";
import { signersAtom } from "../../jotai/atoms.jotai";
import {
  faCheckCircle,
  faLink,
  faSignature,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AndesTooltip } from "../../uiComponents/AndesTooltip/AndesTooltip";
import { colorPallette } from "../../Common/theme";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { useHistory } from "react-router-dom";

export const ESignersDataLinks: React.FC = () => {
  const history = useHistory();
  const [signers] = useAtom(signersAtom) ?? [];
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const copyToClipboard = (text: string, type: "email" | "contract") => {
    navigator.clipboard.writeText(text).then(() => {
      const message =
        type === "email"
          ? "Email del firmante copiado exitosamente!"
          : "Link para firmar copiado! Envíelo al firmante y podrá firmar accediendo al link.";
      setSnackbarMessage(message);
      setSnackbarOpen(true);
    });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleRedirectAndReload = () => {
    history.push("/dashboard/esignature");
    window.location.reload();
  };

  return (
    <Stack alignItems="center" className="animate">
      <Stack
        sx={{
          marginTop: "30px",
          justifyContent: "center",
          maxWidth: 1300,
          width: "100%",
        }}
      >
        <Box sx={{ width: "100%", textAlign: "center", mb: 4 }}>
          <Box
            className="roboto bold"
            sx={{
              fontSize: "2.4rem",
              mb: 4,
            }}
          >
            <FontAwesomeIcon
              icon={faCheckCircle}
              className="mr1"
              style={{ color: colorPallette.primary }}
            />
            Firma Electrónica Generada con Éxito
          </Box>
          <Typography variant="body1">
            Para que cada firmante pueda firmar el contrato, envíe el siguiente
            enlace a cada uno de ellos:
          </Typography>
        </Box>

        {signers?.length > 0 &&
          signers.map((signer) => (
            <Stack
              sx={{
                backgroundColor: "#ffffff",
                width: "100%",
                borderRadius: "15px",
                padding: "15px 20px 20px",
                display: "flex",
                flexDirection: "column",
                boxShadow: "0px 14px 37px -8px rgba(0, 0, 0, 0.13)",
                margin: "10px 0",
                my: 2,
              }}
              key={signer.email}
            >
              <Box sx={{ display: "flex", gap: 1 }}>
                <strong>Firmante:</strong>
                <Typography fontWeight={"bold"} color={colorPallette.primary}>
                  {signer.name}
                </Typography>
              </Box>
              <Box
                sx={{
                  height: "1px",
                  backgroundColor: colorPallette.secondary,
                  marginY: 1,
                  width: "100%",
                  opacity: 0.9,
                }}
              />
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <IconButton
                  onClick={() => copyToClipboard(signer.email, "email")}
                  sx={{
                    "&:hover": {
                      bgcolor: "#ffffff",
                      opacity: 0.5,
                      transform: "rotate(+10deg)",
                      transition: "all 0.3s ease",
                    },
                  }}
                >
                  <AndesTooltip description="Copiar email" icon={faLink}>
                    <FontAwesomeIcon
                      icon={faCopy}
                      size="sm"
                      color={colorPallette.primary}
                    />
                  </AndesTooltip>
                </IconButton>
                <strong>Email:</strong>
                <AndesTooltip description="Copiar email">
                  <Typography
                    fontWeight={"bold"}
                    color={colorPallette.primary}
                    sx={{ cursor: "pointer" }}
                    onClick={() => copyToClipboard(signer.email, "email")}
                  >
                    {signer.email}
                  </Typography>
                </AndesTooltip>
              </Box>
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <IconButton
                  onClick={() => copyToClipboard(signer.sign_url, "contract")}
                  sx={{
                    "&:hover": {
                      bgcolor: "#ffffff",
                      opacity: 0.5,
                      transform: "rotate(+10deg)",
                      transition: "all 0.3s ease",
                    },
                  }}
                >
                  <AndesTooltip
                    description="Copiar link del contrato"
                    icon={faCopy}
                  >
                    <FontAwesomeIcon
                      icon={faCopy}
                      size="sm"
                      color={colorPallette.primary}
                    />
                  </AndesTooltip>
                </IconButton>
                <strong>Contrato a firmar:</strong>
                <AndesTooltip description="Copiar link del contrato">
                  <Typography
                    fontWeight={"bold"}
                    color={colorPallette.primary}
                    sx={{ cursor: "pointer" }}
                    onClick={() => copyToClipboard(signer.sign_url, "contract")}
                  >
                    {signer.sign_url}
                  </Typography>
                </AndesTooltip>
              </Box>
            </Stack>
          ))}

        <Box
          component="button"
          sx={{
            bgcolor: colorPallette.primary,
            cursor: "pointer",
            borderRadius: "9px",
            border: "none",
            height: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transition: "all 0.12s",
            gap: 1,
            mt: 2,
            "&:hover": {
              bgcolor: colorPallette.dark,
            },
          }}
          onClick={handleRedirectAndReload}
        >
          <FontAwesomeIcon icon={faSignature} color={colorPallette.white} />
          <Typography color={colorPallette.white}>
            Continuar a Detalle de Firmas
          </Typography>
        </Box>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
          ContentProps={{
            sx: {
              backgroundColor: colorPallette.primary,
            },
          }}
        />
      </Stack>
    </Stack>
  );
};
