import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { colorPallette } from "../../Common/theme";

interface TabSelectorProps {
  options: string[]; // Up to three options
  onChange: (selected: number) => void;
  width?: string;
  currentValue?: number;
}

const TabSelector: React.FC<TabSelectorProps> = ({
  options,
  onChange,
  width,
  currentValue,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(currentValue || 0);

  useEffect(() => {
    if (currentValue && currentValue !== selectedIndex) {
      setSelectedIndex(currentValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  if (options.length > 3) {
    console.error("TabSelector only supports up to three options.");
    return null;
  }

  const handleTabClick = (index: number) => {
    setSelectedIndex(index);
    onChange(index);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        position: "relative",
        borderRadius: "4px",
        backgroundColor: colorPallette.white,
        fontSize: "0.9rem",
        width: width || "100%",
        height: "40px",
      }}
    >
      {/* Sliding indicator */}
      <Box
        sx={{
          position: "absolute",
          height: "90%",
          width: `${100 / options.length}%`,
          backgroundColor: colorPallette.secondary,
          transition: "transform 0.3s ease-in-out",
          transform: `translateX(${selectedIndex * 100}%)`,
          zIndex: 1,
          borderRadius: "4px",
          boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.09)",
        }}
      />

      {options.map((option, index) => (
        <Box
          key={index}
          onClick={() => handleTabClick(index)}
          sx={{
            flex: 1,
            padding: "8px 16px",
            textAlign: "center",
            cursor: "pointer",
            position: "relative",
            transition: "all 0.3s 0.2s",
            zIndex: 2, // Ensure text is above the sliding indicator
            fontWeight: selectedIndex === index ? 700 : 400,
            color:
              selectedIndex === index
                ? colorPallette.dark
                : colorPallette.darkSecondary,
            userSelect: "none",
          }}
        >
          {option}
        </Box>
      ))}
    </Box>
  );
};

export default TabSelector;
