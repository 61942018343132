import {
  AlignmentType,
  Footer,
  Header,
  ImageRun,
  PageNumber,
  Paragraph,
  TextRun,
} from "docx";
import { kw_footer_sign, kw_pilar_logo } from "../docImages/kw-pilar";

export const styles_kw_pilar = {
  companyId: ["12418"],
  companyName: "Keller Williams Pilar",
  font: "Helvetica",
  titleSize: 24,
  textSize: 24,
  lineSpacing: 250,
  marginRight: 1800,
  marginLeft: 1800,
  beforeParagraph: 300,
  footer: new Footer({
    children: [
      new Paragraph({
        alignment: AlignmentType.RIGHT,
        spacing: {
          before: 0,
          after: 300,
        },
        children: [
          new ImageRun({
            data: kw_footer_sign,
            transformation: {
              width: 112.69,
              height: 80,
            },
          }),
        ],
      }),

      new Paragraph({
        spacing: {
          before: 0,
          after: 0,
        },
        alignment: AlignmentType.CENTER,
        children: [
          new TextRun({
            text: "KW Pilar| Los Crisantemos 392, 2° piso, oficina 204, Pilar, Prov. de Bs As| 11 5239-8172",
            size: 20,
            font: "Calibri",
          }),
        ],
      }),
      new Paragraph({
        spacing: {
          before: 60,
          after: 200,
        },
        alignment: AlignmentType.CENTER,
        children: [
          new TextRun({
            text: "Cada oficina de la red Keller Williams opera de forma independiente",
            size: 18,
            font: "Calibri",
          }),
        ],
      }),
      new Paragraph({
        alignment: AlignmentType.RIGHT,
        children: [
          new TextRun({
            children: [PageNumber.CURRENT],
          }),
        ],
      }),
    ],
  }),
  header: new Header({
    children: [
      new Paragraph({
        spacing: {
          before: 0,
          after: 550,
        },
        alignment: AlignmentType.RIGHT,
        children: [
          new ImageRun({
            data: kw_pilar_logo,
            transformation: {
              width: 100.69,
              height: 43,
            },
          }),
        ],
      }),
    ],
  }),
};
