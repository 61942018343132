import { border, borderRadius, color, padding } from "@mui/system";
import React from "react";
import Selector from "react-select";
import { colorPallette } from "../../Common/theme";
// import "../styles.css";

type Option = {
  value: string;
  label: string;
};

type Props = {
  options: Option[];
  onChange?: (e: any) => void;
  defaultValue?: Option;
  placeholder?: string;
  style?: any;
};

const Select: React.FC<Props> = ({
  options,
  onChange,
  defaultValue,
  placeholder,
  style,
}) => {
  const getBackggroundColor = (state: any) => {
    if (state.isSelected) return "#a8bbfd";
    if (state.isFocused) return "#e8eeff";
    return "white";
  };

  const getColor = (state: any) => {
    if (state.isSelected) return "white";
    if (state.isFocused) return "#818181";
    return colorPallette.dark;
  };

  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      color: getColor(state),
      backgroundColor: getBackggroundColor(state),
    }),
    container: (provided: any, state: any) => ({
      ...provided,
      width: "100%",
      height: "44px",
      border: "none",
      marginBottom: 20,
      marginTop: 20,
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      border: "solid 2px #d8e1fe",
      borderRadius: "10px",
      paddingLeft: "8px",
    }),
    input: (provided: any) => ({
      ...provided,
      minHeight: "1px",
      textAlign: "left",
      height: "44px",
      color: colorPallette.dark,
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      color: colorPallette.dark,
      textAlign: "left",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: colorPallette.dark,
    }),
    menu: (provided: any) => ({
      ...provided,
      marginTop: "19px",
    }),
    menuList: (provided: any) => ({
      ...provided,
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: colorPallette.dark,
      textAlign: "left",
      opacity: 0.7,
    }),
  };

  return (
    <div style={style} className="question-format-form">
      {options.length > 0 && (
        <Selector
          required
          options={options || []}
          defaultValue={defaultValue}
          isSearchable={true}
          styles={{ ...customStyles }}
          placeholder={placeholder || "Seleccionar"}
          noOptionsMessage={() => "No hay opciones que coincidan"}
          onChange={(e) => {
            if (onChange) onChange(e);
          }}
        />
      )}
    </div>
  );
};

export default Select;
