import {
  AlignmentType,
  Footer,
  Header,
  ImageRun,
  PageNumber,
  Paragraph,
  TextRun,
} from "docx";
import { loza_ordano_logo } from "../docImages/loza-ordano";

export const styles_loza_ordano = {
  companyId: ["12419"],
  companyName: "Loza Ordano",
  font: "Arial",
  titleSize: 22,
  textSize: 22,
  lineSpacing: 450,
  marginRight: 1000,
  marginLeft: 1000,
  beforeParagraph: 300,

  header: new Header({
    children: [
      new Paragraph({
        spacing: {
          before: 0,
          after: 450,
        },
        alignment: AlignmentType.CENTER,
        children: [
          new ImageRun({
            data: loza_ordano_logo,
            transformation: {
              width: 150,
              height: 50,
            },
          }),
        ],
      }),
    ],
  }),

  footer: new Footer({
    children: [
      new Paragraph({
        spacing: {
          before: 300,
          after: 0,
        },
        alignment: AlignmentType.CENTER,
        children: [],
      }),
      new Paragraph({
        alignment: AlignmentType.RIGHT,
        children: [
          new TextRun({
            children: [PageNumber.CURRENT],
          }),
        ],
      }),
    ],
  }),
};
