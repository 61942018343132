import { Box, Stack } from "@mui/material";
import React from "react";
import {
  faArrowRight,
  faBolt,
  faHourglass2,
  faPuzzlePiece,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { removeDuplicates } from "../../Utils/numberMethods";
import { colorPallette } from "../../Common/theme";

type Props = { currentSection: number; watch: any; isReview: boolean };

type SectionProps = {
  section: number;
  currentSection: number;
  sectionName: string;
  isReview: boolean;
};

const Section: React.FC<SectionProps> = ({
  section,
  currentSection,
  sectionName,
  isReview,
}) => {
  return (
    <Stack
      my={3}
      height={"70px"}
      borderRadius={3}
      textAlign={"left"}
      px={2}
      className={currentSection === section && !isReview ? "b-secondary" : ""}
      flexDirection={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      position={"relative"}
      sx={{ opacity: currentSection < section || isReview ? 0.3 : 1 }}
    >
      <Stack
        flexDirection="row"
        width="265px"
        alignItems="center"
        sx={{
          "& input": {
            margin: 0,
          },
        }}
      >
        <span
          className="bold"
          style={{ width: "85%", marginRight: "8px", fontSize: 16 }}
        >
          <FontAwesomeIcon
            icon={faPuzzlePiece}
            style={{ marginRight: 8, color: colorPallette.darkSecondary }}
          />
          {sectionName ? `${section} - ${sectionName}` : `Sección ${section}`}
        </span>
      </Stack>
      <Box sx={{ cursor: "pointer" }}>
        {(currentSection > section || isReview) && (
          <FontAwesomeIcon
            style={{ color: colorPallette.darkSecondary }}
            icon={faCircleCheck}
          />
        )}
        {currentSection === section && !isReview && (
          <FontAwesomeIcon
            style={{ color: colorPallette.primary }}
            icon={faArrowRight}
          />
        )}
        {currentSection < section && (
          <FontAwesomeIcon
            style={{ color: colorPallette.primary }}
            icon={faHourglass2}
          />
        )}
      </Box>
    </Stack>
  );
};

const ReviewSection: React.FC<any> = ({ isReview }) => {
  return (
    <Stack
      my={3}
      height={"70px"}
      borderRadius={3}
      textAlign={"left"}
      px={2}
      className={isReview ? "b-secondary" : ""}
      flexDirection={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      position={"relative"}
      sx={{ opacity: !isReview ? 0.3 : 1 }}
    >
      <Stack
        flexDirection="row"
        width="265px"
        alignItems="center"
        sx={{
          "& input": {
            margin: 0,
          },
        }}
      >
        <span
          className="bold"
          style={{ width: "85%", marginRight: "8px", fontSize: 16 }}
        >
          <FontAwesomeIcon
            icon={faBolt}
            style={{ marginRight: 9 }}
            className="primary"
          />
          Revisión y Descarga
        </span>
      </Stack>
      <Box sx={{ cursor: "pointer" }}>
        {isReview && (
          <FontAwesomeIcon className="primary" icon={faArrowRight} />
        )}
        {!isReview && (
          <FontAwesomeIcon className="primary" icon={faHourglass2} />
        )}
      </Box>
    </Stack>
  );
};

const GeneratorFormSections: React.FC<Props> = ({
  currentSection,
  isReview,
}) => {
  const lsSections = JSON.parse(
    localStorage.getItem("generator-form-sections") || "{}"
  );

  const { questions } = JSON.parse(
    localStorage.getItem("generator-form-data") || '{"questions": {}}'
  );
  const allQuestionSections = Object.keys(questions).map((question) =>
    Number(questions[question].section)
  );

  let customSectionNumbers = Object.keys(lsSections).map((sect: string) =>
    Number(sect)
  );

  if (Object.keys(lsSections).length < 1) {
    customSectionNumbers = removeDuplicates(allQuestionSections);
    const newLsSections: any = {};
    customSectionNumbers.forEach((sect: number) => {
      newLsSections[sect] = `Sección ${sect}`;
    });
    localStorage.setItem(
      "generator-form-sections",
      JSON.stringify(newLsSections)
    );
  }
  const allSectionNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const maxSectionNumber = Math.max(...customSectionNumbers) || 1;

  const sectionNumbers = allSectionNumbers.filter(
    (e: number) => e <= maxSectionNumber
  );

  const finalSectionNumbers = sectionNumbers.filter(
    (sect: number) =>
      customSectionNumbers.includes(sect) && allQuestionSections.includes(sect)
  );

  return (
    <Stack sx={{ width: "380px" }} alignItems={"center"} className="b-white">
      <Box mt={5} mb={1}>
        Secciones: {maxSectionNumber}
      </Box>
      <Box mt={1} width={"90%"}>
        {finalSectionNumbers.map((section: number) => (
          <Section
            key={section}
            section={section}
            currentSection={currentSection}
            sectionName={lsSections[section] || `Sección ${section}`}
            isReview={isReview}
          />
        ))}
        <ReviewSection isReview={isReview} />
      </Box>
    </Stack>
  );
};

export default GeneratorFormSections;
