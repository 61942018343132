import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faHourglass2 } from "@fortawesome/free-solid-svg-icons";
import "./TableEntries.styles.css";
import { colorPallette } from "../../../Common/theme";

type Props = {
  status: string;
};

const SignatureStatusEntry: React.FC<Props> = ({ status }) => {
  if (status === "signed")
    return (
      <div className="primary bold table-entry-aproval">
        <FontAwesomeIcon icon={faCheckCircle} className="icon-margin-r" />
        Firma Completa
      </div>
    );

  return (
    <div
      className="table-entry-aproval"
      style={{ color: colorPallette.primary, opacity: 0.5 }}
    >
      {/* <FontAwesomeIcon icon={faHourglass2} className="icon-margin-r" /> */}
      Pendiente
    </div>
  );
};

export default SignatureStatusEntry;
