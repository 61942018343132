import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, Typography } from "@mui/material";
import { colorPallette } from "../../Common/theme";
import { SxProps } from "@mui/system";

interface LogOutButtonProps {
  sx?: SxProps;
}

const currentUrl =
  process.env.REACT_APP_ENV === "development"
    ? "http://localhost:3000"
    : "https://andesdocs.com";

const LogOutButton: React.FC<LogOutButtonProps> = ({ sx }) => {
  const { logout } = useAuth0();
  return (
    <Box
      onClick={() => {
        logout({ returnTo: `${currentUrl}/` });
      }}
      sx={{
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        borderRadius: "4px",
        justifyContent: "center",
        cursor: "pointer",
        transition: "all 0.2s",
        color: colorPallette.dark,
        fontWeight: "bold",
        alignSelf: "center",
        bgcolor: colorPallette.secondary,
        "&:hover": {
          backgroundColor: colorPallette.yellow,
          color: colorPallette.dark,
          "& .MuiTypography-root": {
            color: colorPallette.dark,
          },
        },
        ...sx,
      }}
    >
      Log Out
    </Box>
  );
};

export default LogOutButton;
