import { Document } from "../redux/slices/masterData";
import store from "../redux/store";
import { setAppError, setLoading } from "./modalMethods";
import { downloadWordS3 } from "./S3Download";

export const downloadDocumentFromS3 = async (
  document: Document,
  accessToken: any
) => {
  try {
    setLoading(true);

    await downloadWordS3(document, accessToken);
  } catch (error) {
    setAppError();
  } finally {
    setLoading(false);
  }
};

export const filterAlarmDocuments = (documents: any, range = 7) => {
  const today = Date.now();

  return documents.filter((doc: any) => {
    let expDate = doc.expirationDate;
    if (typeof expDate === "string") {
      expDate = parseInt(expDate);
    }
    const daysToExpiration = Math.floor(
      (expDate - today) / (1000 * 60 * 60 * 24)
    ); // Convert to days

    return daysToExpiration <= range && daysToExpiration >= -range;
  });
};

export const findUserPhotoUrl = (userId: string) => {
  const { allCompanyUsers } = store.getState().masterData;
  const user = allCompanyUsers.find((user) => user.userId === userId);
  return user ? user.photoUrl : "Sin foto";
};

export const findUserNameById = (userId: string) => {
  const { allCompanyUsers } = store.getState().masterData;
  const user = allCompanyUsers.find((user) => user.userId === userId);
  return user ? user.name : "Usuario Removido";
};
