import React, { useMemo } from "react";
import { MRT_ColumnDef } from "material-react-table";
import TableEntryName from "../../../uiComponents/AndesTable/TableEntries/Name.component";
import {
  faTrashAlt,
  faDownload,
  faCheckCircle,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import TableDate from "../../../uiComponents/AndesTable/TableEntries/TableDate.component";
import DaysToExpiration from "../TableEntries/DaysToExpiration.component";
import TableActions, {
  TableAction,
} from "../../../uiComponents/AndesTable/TableEntries/TableActions.components";
import {
  downloadDocumentFromS3,
  findUserNameById,
  findUserPhotoUrl,
} from "../../../Utils/commonTableActions";
import { setAppError, setLoading } from "../../../Utils/modalMethods";
import AndesTable, {
  renderHeader,
} from "../../../uiComponents/AndesTable/AndesTable.component";
import { ProfilePicAndName } from "../../../uiComponents/ProfilePicAndName/ProfilePicAndName";
import { APIPostWithError } from "../../../Services/authenticated";
import { useAuth0 } from "@auth0/auth0-react";
import { updateDocumentOnAtom } from "../../../jotai/documents.jotai";
import { setConfirmationModalAtom } from "../../../jotai/confimationModalAtom.jotai";
import { colorPallette } from "../../../Common/theme";
import { setCommonComponents } from "../../../jotai/commonComponentAtom.jotai";

type Props = {
  documents: any[];
};

const AlarmTable: React.FC<Props> = ({ documents }) => {
  const { getAccessTokenSilently } = useAuth0();
  const editDocument = async (payload: any) => {
    try {
      setLoading(true);
      const accessToken = await getAccessTokenSilently();
      const res = await APIPostWithError(
        "/doc-ref/edit-document",
        accessToken,
        payload
      );
      if (res) {
        updateDocumentOnAtom(payload);
      } else {
        throw new Error("Error al editar el documento");
      }
    } catch (error) {
      setAppError();
    } finally {
      setLoading(false);
    }
  };

  const alarmTableActions: TableAction[] = [
    {
      name: "Descargar",
      icon: faDownload,
      onClickAsync: (data: any, accessToken: any) =>
        downloadDocumentFromS3(data, accessToken),
    },
    {
      name: "Marcar como renovado",
      icon: faCheckCircle,
      onClickWithParams: (data: any) =>
        setConfirmationModalAtom({
          onConfirm: () => {
            editDocument({ ...data, isExpirationHandled: true });
          },
          message: "Confirmar renovación",
          subMessage: `¿Seguro desea marcar el documento: ${data.fileName} como RENOVADO?`,
          disclaimer: "*No se podrá deshacer",
          show: true,
        }),
    },
    {
      name: "Editar Fecha",
      icon: faEdit,
      onClickWithParams: (data: any) =>
        setCommonComponents({
          showExpirationModal: true,
          currentDocument: data,
        }),
    },
    {
      name: "Eliminar",
      icon: faTrashAlt,
      onClickWithParams: (data: any) =>
        setConfirmationModalAtom({
          onConfirm: () => {
            editDocument({ ...data, expirationDate: null });
          },
          message: "Confirmar eliminación de alarma",
          subMessage: `Documento: ${data.fileName}`,
          disclaimer:
            "*Solo se eliminara el vencimiento y alarma, no el documento",
          show: true,
        }),
      color: colorPallette.primary,
    },
  ];

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "fileName",
        header: "Nombre",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        Cell: ({ cell }: any) => {
          return (
            <TableEntryName
              name={cell.getValue()}
              smaller
              image={
                cell.row.original.isExpirationHandled
                  ? "alarm-renewed.png"
                  : "alarm.png"
              }
            />
          );
        },
        maxSize: 400,
        minSize: 310,
      },
      {
        accessorKey: "expirationDate",
        header: "Vencimiento",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        minSize: 190,
        maxSize: 170,
        Cell: ({ cell }: any) => {
          return (
            <TableDate date={cell.getValue()} fallback="Sin definir" hideTime />
          );
        },
      },
      {
        accessorKey: "daysToExpiration",
        header: "Dias para vencer",
        minSize: 210,
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        Cell: ({ cell }: any) => {
          return (
            <DaysToExpiration
              date={cell.getValue()}
              isHandled={cell.row.original.isExpirationHandled || false}
            />
          );
        },
        sortDescFirst: false,
      },
      {
        accessorKey: "userId",
        header: "Creado por",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        minSize: 220,
        Cell: ({ cell }: any) => {
          const userPhotoUrl = findUserPhotoUrl(cell.getValue());
          const userName = findUserNameById(cell.getValue());
          return <ProfilePicAndName image={userPhotoUrl} name={userName} />;
        },
      },
      {
        header: "Acciones",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        enableColumnActions: false,
        minSize: 220,
        Cell: ({ cell }) => (
          <TableActions
            actions={alarmTableActions}
            rowData={cell.row.original}
          />
        ),
      },
    ],
    []
  );

  return (
    <AndesTable
      documents={documents}
      columns={columns}
      initialSort={[{ id: "daysToExpiration", desc: false }]}
    />
  );
};

export default AlarmTable;
