import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type Document = {
  amount?: string;
  andesDockerDoc: boolean;
  approvedBy: string;
  comments: string;
  companyId: string;
  companyName: string;
  createdBy: string;
  creatorPhotoUrl: string;
  dateCreated: string;
  delFlg: number;
  description: string;
  documentId: string;
  documentType: string;
  expirationDate?: string;
  fileName: string;
  filePath: string;
  folder?: string | null;
  format: string;
  part1?: string | null;
  part2?: string | null;
  part3?: string | null;
  part4?: string | null;
  size: number;
  status: string;
  uniqueKey: string;
  updateDate: string;
  userId: string;
  versionId: string;
  versionNumber: number;
};

export type CompanyUser = {
  userId: string;
  name: string;
  photoUrl: string;
  internalUser?: boolean;
  companyName?: string;
};

export interface masterDataState {
  company: any;
  folders: null | any[];
  companyDocuments: null | any[];
  spaceUsage: number;
  allCompanyUsers: CompanyUser[];
  user: {
    userId: string;
    companyId: string;
    photoUrl: string;
    role: string;
    documentShare: boolean;
    createdAt: string;
    name: string | null;
    email: string | null;
    internalUser: boolean;
  } | null;
}

const initialState: masterDataState = {
  company: null,
  folders: null,
  companyDocuments: null,
  spaceUsage: 0,
  allCompanyUsers: [],
  user: null,
};

export const masterDataSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    setCompany: (state, action: PayloadAction<any>) => {
      state.company = action.payload;
    },
    setFolders: (state, action: PayloadAction<any>) => {
      state.folders = action.payload;
    },
    setCompanyDocuments: (state, action: PayloadAction<any>) => {
      state.companyDocuments = action.payload;
    },
    setSpaceUsage: (state, action: PayloadAction<any>) => {
      state.spaceUsage = action.payload;
    },
    setUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
    setAllCompanyUsers: (state, action: PayloadAction<any>) => {
      state.allCompanyUsers = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCompany,
  setCompanyDocuments,
  setFolders,
  setSpaceUsage,
  setUser,
  setAllCompanyUsers,
} = masterDataSlice.actions;

export default masterDataSlice.reducer;
