import { Box } from "@mui/material";
import { ProfilePicAndName } from "../../../../uiComponents/ProfilePicAndName/ProfilePicAndName";
import {
  findUserNameById,
  findUserPhotoUrl,
} from "../../../../Utils/commonTableActions";

type CreatorFieldProps = {
  document: any;
};

const CreatorField: React.FC<CreatorFieldProps> = ({ document }) => {
  const userName = findUserNameById(document?.userId);
  const profileImage = findUserPhotoUrl(document?.userId);

  return (
    <Box my={2} pl={1}>
      <ProfilePicAndName image={profileImage} name={userName} />
    </Box>
  );
};

export default CreatorField;
