import { faBolt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import React from "react";
import { colorPallette } from "../../../Common/theme";

type DashboardSectionTitleProps = {
  title: string;
  subMessage?: string;
  subComponent?: React.ReactNode;
};

const DashboardSectionTitle: React.FC<DashboardSectionTitleProps> = ({
  title,
  subMessage,
  subComponent,
}) => {
  return (
    <div className="animate" style={{ position: "relative" }}>
      <h2 className="a-left" style={{ fontSize: "1.5rem" }}>
        <FontAwesomeIcon
          icon={faBolt}
          style={{
            fontSize: "1rem",
            transform: "translateY(-2px)",
            marginRight: "8px",
            color: colorPallette.darkSecondary,
          }}
        />
        {title}
      </h2>
      {subMessage && (
        <Box
          sx={{
            position: "absolute",
            fontSize: "0.9rem",
            top: 36,
            marginLeft: "25px",
          }}
        >
          {subMessage}
        </Box>
      )}
      {subComponent && subComponent}
    </div>
  );
};

export default DashboardSectionTitle;
