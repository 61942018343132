// import IconButton from "../../IconButton/IconButton";
import { useAtomValue } from "jotai";
import {
  confirmationModalAtom,
  initialConfirmationModalState,
  setConfirmationModalAtom,
} from "../../../jotai/confimationModalAtom.jotai";
import AndesModal from "../../../uiComponents/AndesModal/AndesModal";

const JotaiConfirmModal: React.FC = () => {
  const {
    message,
    subMessage,
    onClose,
    onConfirm,
    disclaimer = null,
  } = useAtomValue(confirmationModalAtom);

  const handleClose = () => {
    onClose && onClose();
    setConfirmationModalAtom(initialConfirmationModalState);
  };

  const handleConfirm = () => {
    onConfirm && onConfirm();
    setConfirmationModalAtom(initialConfirmationModalState);
  };

  return (
    <AndesModal
      message={message}
      subMessage={subMessage}
      disclaimer={disclaimer}
      handleConfirm={handleConfirm}
      handleReject={handleClose}
    />
  );
};

export default JotaiConfirmModal;
