import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ProfilePic from "../ProfilePic/ProfilePic";
import { colorPallette } from "../../Common/theme";

// Colores a rotar
const colors = [
  colorPallette.secondary,
  colorPallette.secondary,
  colorPallette.secondary,
];

// Función para obtener el color basado en la letra
const getColorForLetter = (letter: string): string => {
  const letterIndex = letter.charCodeAt(0) - "A".charCodeAt(0); // Obtener índice basado en la letra
  const colorIndex = letterIndex % colors.length; // Rotar entre colores
  return colors[colorIndex];
};

type Props = {
  image: string;
  name: string;
};

export const ProfilePicAndName: React.FC<Props> = ({ image, name }) => {
  const namesArray = name.split(" ");
  const firstLetter = namesArray[0]
    ? namesArray[0].charAt(0).toUpperCase()
    : "";
  const secondLetter = namesArray[1]
    ? namesArray[1].charAt(0).toUpperCase()
    : "";
  const initials = firstLetter + secondLetter;
  const showPlaceholder = !image;

  const [bgColor, setBgColor] = useState<string>("");

  useEffect(() => {
    // Asignar color basado en la primera letra
    const color = getColorForLetter(firstLetter);
    setBgColor(color);
  }, [firstLetter]);

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
      {showPlaceholder ? (
        <Box
          sx={{
            minWidth: 40,
            minHeight: 40,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: bgColor,
            borderRadius: "50%",
            color: colorPallette.dark,
            fontWeight: "bold",
          }}
        >
          {initials || "?"}
        </Box>
      ) : (
        <ProfilePic photoUrl={image} size={40} editable={false} />
      )}
      <Box
        // variant="body2"
        fontWeight={"bold"}
        sx={{ fontSize: "0.8rem" }}
        color={colorPallette.dark}
      >
        {name}
      </Box>
    </Box>
  );
};
