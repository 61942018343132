import {
  faFilter,
  faPencil,
  faPuzzlePiece,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Stack, Tooltip } from "@mui/material";
import { useState } from "react";
import { Controller } from "react-hook-form";

import styles from "./QuestionGeneratorForm.module.css";
import { colorPallette } from "../../Common/theme";

export const Section: React.FC<any> = ({
  control,
  section,
  watch,
  handleFilterSection,
  isSelected,
}) => {
  const [showInput, setShowInput] = useState(false);

  const currentValue = watch()[String(section)];

  return (
    <Stack
      my={3}
      height={"70px"}
      borderRadius={3}
      textAlign={"left"}
      px={2}
      className="b-secondary"
      flexDirection={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      position={"relative"}
    >
      <Stack
        flexDirection="row"
        width="265px"
        alignItems="center"
        sx={{
          "& input": {
            margin: 0,
          },
        }}
      >
        <span
          className="bold"
          style={{ width: "85%", marginRight: "8px", fontSize: 16 }}
        >
          <FontAwesomeIcon
            icon={faPuzzlePiece}
            style={{ marginRight: 8, color: colorPallette.darkSecondary }}
          />
          {section} - {currentValue || `Sección ${section}`}
        </span>
        <Box
          position="absolute"
          width="70%"
          sx={{
            opacity: showInput ? 1 : 0,
            pointerEvents: showInput ? "all" : "none",
          }}
          left={40}
        >
          <Controller
            name={String(section)}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Stack p={0}>
                <input
                  value={value}
                  style={
                    error && {
                      borderColor: "red",
                      backgroundColor: "#fef7f6",
                    }
                  }
                  className={styles.input}
                  maxLength={25}
                  onChange={onChange}
                  defaultValue={value}
                />
              </Stack>
            )}
          />
        </Box>
      </Stack>
      <Box display="flex" gap={1}>
        <Tooltip title="Filtrar sección">
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => handleFilterSection(section)}
          >
            <FontAwesomeIcon
              className={`${isSelected ? "#andes" : "primary"}`}
              icon={faFilter}
            />
          </Box>
        </Tooltip>
        <Tooltip title="Editar sección">
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setShowInput((prev) => !prev);
            }}
          >
            <FontAwesomeIcon className="primary" icon={faPencil} />
          </Box>
        </Tooltip>
      </Box>
    </Stack>
  );
};
