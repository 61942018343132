import React, { useMemo } from "react";
import { MRT_ColumnDef } from "material-react-table";
import TableEntryName from "../../../uiComponents/AndesTable/TableEntries/Name.component";
import TableDate from "../../../uiComponents/AndesTable/TableEntries/TableDate.component";
import TableActions, {
  TableAction,
} from "../../../uiComponents/AndesTable/TableEntries/TableActions.components";

import AndesTable, {
  renderHeader,
} from "../../../uiComponents/AndesTable/AndesTable.component";
import {
  faTrashAlt,
  faDownload,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import SignatureStatusEntry from "../TableEntries/SignatureStatusEntry";
import { setLoading } from "../../../Utils/modalMethods";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  findUserNameById,
  findUserPhotoUrl,
} from "../../../Utils/commonTableActions";
import { ProfilePicAndName } from "../../../uiComponents/ProfilePicAndName/ProfilePicAndName";
import {
  APIGetWithError,
  APIPostWithError,
} from "../../../Services/authenticated";
import { useAuth0 } from "@auth0/auth0-react";
import { downloadExternalPublicS3file } from "../../../Utils/S3Download";
import { setCommonComponents } from "../../../jotai/commonComponentAtom.jotai";
import { setConfirmationModalAtom } from "../../../jotai/confimationModalAtom.jotai";
import {
  ElectronicSignature,
  removeElectronicSignatureFromAtom,
} from "../../../jotai/electronicSignatures.jotai";
import { colorPallette } from "../../../Common/theme";

type Props = {
  eSignatures: ElectronicSignature[];
};

const AllSignaturesTable: React.FC<Props> = ({ eSignatures }) => {
  const { getAccessTokenSilently } = useAuth0();

  const deleteDBSignature = async (signature: any) => {
    setLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const deleteSignature = await APIPostWithError(
        "/electronic-signature/delete-signature",
        accessToken,
        { token: signature.documentToken }
      );
      if (deleteSignature.signatureDeleted) {
        removeElectronicSignatureFromAtom(signature);
      }
    } finally {
      setLoading(false);
    }
  };

  const alarmTableActions: TableAction[] = [
    {
      name: "Descargar Firmado",
      icon: faDownload,
      onClickAsync: async (data: ElectronicSignature) => {
        try {
          setLoading(true);
          if (data.signedFile) {
            downloadExternalPublicS3file(data.signedFile, "pdf", data.fileName);
          } else {
            const accessToken = await getAccessTokenSilently();
            // Llamada al nuevo endpoint para verificar firmantes
            const response = await APIGetWithError(
              `/electronic-signature/get-signed-file?docToken=${data.documentToken}`,
              accessToken
            );

            if (response.signedFile) {
              downloadExternalPublicS3file(
                response.signedFile,
                "pdf",
                data.fileName
              );
            }
          }
        } finally {
          setLoading(false);
        }
      },
    },
    {
      name: "Eliminar",
      icon: faTrashAlt,
      onClickWithParams: (data: any) =>
        setConfirmationModalAtom({
          show: true,
          message: "Confirme eliminación",
          subMessage: "¿Seguro desea eliminar la firma seleccionada?",
          disclaimer:
            "*No se podrá deshacer, asegursese que no necesitará la firma en el futuro.",
          onConfirm: () => deleteDBSignature(data),
        }),
      color: colorPallette.primary,
    },
  ];

  const columns = useMemo<MRT_ColumnDef<any>[]>(() => {
    return [
      {
        accessorKey: "fileName",
        header: "Nombre",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        Cell: ({ cell }: any) => {
          return (
            <TableEntryName name={cell.getValue()} image={"signature.png"} />
          );
        },
        maxSize: 400,
        minSize: 310,
      },
      {
        accessorKey: "status",
        header: "Estado",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        minSize: 170,
        maxSize: 170,
        Cell: ({ cell }: any) => {
          return <SignatureStatusEntry status={cell.getValue()} />;
        },
      },

      {
        accessorKey: "dateCreated",
        header: "Creación",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        minSize: 160,
        maxSize: 160,
        Cell: ({ cell }: any) => {
          return <TableDate date={cell.getValue()} fallback="Sin definir" />;
        },
      },
      {
        accessorKey: "userId",
        header: "Creado por",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        minSize: 180,
        Cell: ({ cell }: any) => {
          const userName = findUserNameById(cell.getValue());
          const profileImage = findUserPhotoUrl(cell.getValue());
          return <ProfilePicAndName image={profileImage} name={userName} />;
        },
      },
      {
        header: "Acciones",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        enableColumnActions: false,
        minSize: 90,
        maxSize: 120,
        Cell: ({ cell }) => (
          <TableActions
            actions={alarmTableActions}
            rowData={cell.row.original}
          />
        ),
      },
      {
        header: "Firmantes",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        enableColumnActions: false,
        minSize: 240,
        Cell: ({ cell }) => (
          <div>
            <div
              className="btn-outline"
              onClick={() => {
                setCommonComponents({
                  showSignatureStatusModal: true,
                  currentSignature: cell.row.original,
                });
              }}
            >
              <FontAwesomeIcon icon={faPlus} className="margin-icon" />
              Ver Detalle Firmantes
            </div>
          </div>
        ),
      },
    ];
  }, []);

  return (
    <AndesTable
      documents={eSignatures}
      columns={columns}
      initialSort={[{ id: "dateCreated", desc: true }]}
    />
  );
};

export default AllSignaturesTable;
