import {
  Document,
  Paragraph,
  TextRun,
  AlignmentType,
  UnderlineType,
  Header,
  Footer,
  ImageRun,
  PageNumber,
} from "docx";

import soldatiLogo from "../../../../Assets/CompanyLogos/soldati-logo.png";
import soldatiConectaLogo from "../../../../Assets/CompanyLogos/soldati-conecta-logo.png";

import fabianLogo from "../../../../Assets/CompanyLogos/fabian-logo.png";

import toribioAchavalLogo from "../../../../Assets/CompanyLogos/toribio-achaval-logo.png";
import andesLogo from "../../../../Assets/CompanyLogos/andes-logo.png";

import flechaBusLogo from "../../../../Assets/CompanyLogos/flecha-bus-logo.png";
import sanJuanMarLogo from "../../../../Assets/CompanyLogos/san-juan-mar-del-plata-logo.png";
import sanJuanLogo from "../../../../Assets/CompanyLogos/san-juan-logo.png";
import busPackLogo from "../../../../Assets/CompanyLogos/bus-pack-express-logo.png";
import centralLogo from "../../../../Assets/CompanyLogos/central-argentino-logo.png";
import dreudderLogo from "../../../../Assets/CompanyLogos/derruder-logo.png";
import empresaArgentinaLogo from "../../../../Assets/CompanyLogos/empresa-argentina-logo.png";
import coitLogo from "../../../../Assets/CompanyLogos/coit-logo.png";
import norteLogo from "../../../../Assets/CompanyLogos/el-norte-bis-logo.png";
import urquizaLogo from "../../../../Assets/CompanyLogos/urquiza-logo.png";
import gutierrezLogo from "../../../../Assets/CompanyLogos/gutierrez-logo.png";
import rioUruguayLogo from "../../../../Assets/CompanyLogos/rio-uruguay-logo.png";
import sanJoseLogo from "../../../../Assets/CompanyLogos/san-jose-logo.png";
import enconLogo from "../../../../Assets/CompanyLogos/encon-logo.png";
import laPreferidaLogo from "../../../../Assets/CompanyLogos/la-preferida-logo.png";
import velozNorteLogo from "../../../../Assets/CompanyLogos/veloz-del-norte-logo.png";
import marChiquitaLogo from "../../../../Assets/CompanyLogos/mar-chiquita-logo.png";
import nuevaChevallierLogo from "../../../../Assets/CompanyLogos/chevallier-logo.png";
import nuevaGodoyLogo from "../../../../Assets/CompanyLogos/nueva-godoy-logo.png";
import pullmanLogo from "../../../../Assets/CompanyLogos/pullman-logo.png";
import zenitLogo from "../../../../Assets/CompanyLogos/zenit-logo.png";
import nuevoExpresoLogo from "../../../../Assets/CompanyLogos/nuevo-expreso-logo.png";
import brownLogo from "../../../../Assets/CompanyLogos/brown-logo.png";
import clickApartsLogo from "../../../../Assets/CompanyLogos/click-aparts-logo.png";
import guimatLogo from "../../../../Assets/CompanyLogos/guimat-logo.png";
import guimatIso from "../../../../Assets/CompanyLogos/guimat-iso.png";
import izrLogo from "../../../../Assets/CompanyLogos/izr-logo.png";
import andesMarLogo from "../../../../Assets/CompanyLogos/andes-mar-logo.png";

let font = "Arial";
let titleSize = 21;
let textSize = 21;
let lineSpacing = 470;
let marginRight = 780;
let marginLeft = 780;
let beforeParagraph = 300;
let footer = new Footer({
  children: [
    new Paragraph({
      spacing: {
        before: 300,
        after: 100,
      },
      alignment: AlignmentType.CENTER,
      children: [
        new Paragraph({
          alignment: AlignmentType.RIGHT,
          children: [
            new TextRun({
              children: [PageNumber.CURRENT],
            }),
          ],
        }),
      ],
    }),
  ],
});

let header = new Header({
  children: [
    new Paragraph({
      spacing: {
        before: 300,
        after: 100,
      },
      alignment: AlignmentType.RIGHT,
      children: [],
    }),
  ],
});

// Esta funcion es una cagada
const chooseFlechaBusLogo = () => {
  try {
    const apod = window.sessionStorage.getItem("currentLogo");
    if (!apod) {
      return [];
    }

    const getLogo = () => {
      let apoderado = "flecha bus";
      if (apod) {
        apoderado = apod.toLowerCase();
      }
      if (!apod) return flechaBusLogo;

      if (apoderado.includes("EMPRESA COIT LTDA.".toLowerCase())) {
        return coitLogo;
      }
      if (apoderado.includes("EMPRESA EL NORTE BIS".toLowerCase())) {
        return norteLogo;
      }
      if (apoderado.includes("EMPRESA GRAL. URQUIZA".toLowerCase())) {
        return urquizaLogo;
      }
      if (apoderado.includes("EMPRESA GUTIERREZ".toLowerCase())) {
        return gutierrezLogo;
      }
      if (apoderado.includes("EMPRESA MESSINA".toLowerCase())) {
        return flechaBusLogo;
      }
      if (apoderado.includes("EMPRESA RIO URUGUAY ".toLowerCase())) {
        return rioUruguayLogo;
      }
      if (apoderado.includes("EMPRESA SAN JOSE".toLowerCase())) {
        return sanJoseLogo;
      }
      if (apoderado.includes("EXPRESO ENCON".toLowerCase())) {
        return enconLogo;
      }
      if (apoderado.includes("LA PREFERIDA".toLowerCase())) {
        return laPreferidaLogo;
      }
      if (apoderado.includes("LA VELOZ DEL NORTE".toLowerCase())) {
        return velozNorteLogo;
      }
      if (apoderado.includes("MAR CHIQUITA".toLowerCase())) {
        return marChiquitaLogo;
      }
      if (apoderado.includes("NUEVA CHEVALLIER".toLowerCase())) {
        return nuevaChevallierLogo;
      }
      if (apoderado.includes("NUEVA EMPRESA GODOY".toLowerCase())) {
        return nuevaGodoyLogo;
      }
      if (apoderado.includes("PULLMAN GRAL. BELGRANO".toLowerCase())) {
        return pullmanLogo;
      }
      if (apoderado.includes("ZENIT TRANSPORTES".toLowerCase())) {
        return zenitLogo;
      }
      if (
        apoderado.includes(
          "NUEVO EXPRESO de EMPRESA de CIUDAD de GUALEGUAYCHU".toLowerCase()
        )
      ) {
        return nuevoExpresoLogo;
      }

      if (apoderado.includes("GUILLERMO BROWN".toLowerCase())) {
        return brownLogo;
      }
      if (apoderado.includes("SAN JUAN MAR DEL".toLowerCase())) {
        return sanJuanMarLogo;
      }
      if (apoderado.includes("SAN JUAN".toLowerCase())) {
        return sanJuanLogo;
      }
      if (apoderado.includes("BUS PACK".toLowerCase())) {
        return busPackLogo;
      }
      if (apoderado.includes("CENTRAL ARGENTINO DE ROSARIO".toLowerCase())) {
        return centralLogo;
      }
      if (apoderado.includes("DERUDDER HNOS".toLowerCase())) {
        return dreudderLogo;
      }
      if (apoderado.includes("EMPRESA ARGENTINA DE SERV".toLowerCase())) {
        return empresaArgentinaLogo;
      }
      return flechaBusLogo;
    };

    const logo = getLogo();

    if (logo) {
      return [
        new ImageRun({
          data: logo,
          transformation: {
            width: 100.69,
            height: 43,
          },
        }),
      ];
    }
    return [];
  } catch {
    return [];
  }
};

const stylesPerCompany = {
  andes: {
    font: "Arial",
    titleSize: 21,
    textSize: 21,
    lineSpacing: 470,
    marginRight: 780,
    marginLeft: 780,
    beforeParagraph: 300,
    footer: new Footer({
      children: [
        new Paragraph({
          spacing: {
            before: 300,
            after: 0,
          },
          alignment: AlignmentType.CENTER,
          children: [
            new TextRun({
              text: "Andes Docs, automatización de documentos legales: https://andesdocs.com",
              size: 13,
              font: "Arial",
            }),
          ],
        }),
      ],
    }),
    header: new Header({
      children: [
        new Paragraph({
          spacing: {
            before: 0,
            after: 550,
          },
          alignment: AlignmentType.RIGHT,
          children: [
            new ImageRun({
              data: andesLogo,
              transformation: {
                width: 167,
                height: 69.05,
              },
            }),
          ],
        }),
      ],
    }),
  },
  soldati: {
    font: "Arial",
    titleSize: 21,
    textSize: 21,
    lineSpacing: 470,
    marginRight: 780,
    marginLeft: 780,
    beforeParagraph: 300,
    footer: new Footer({
      children: [
        new Paragraph({
          spacing: {
            before: 300,
            after: 0,
          },
          alignment: AlignmentType.CENTER,
          children: [
            new TextRun({
              text: "MERCADO RESIDENCIAL – EMPRENDIMIENTOS E INVERSIONES – MERCADO INTERNACIONAL",
              size: 13,
              font: "Arial",
            }),
          ],
        }),
        new Paragraph({
          spacing: {
            before: 60,
            after: 200,
          },
          alignment: AlignmentType.CENTER,
          children: [
            new TextRun({
              text: "soldati.com / documento creado en andesdocs.com",
              size: 15,
              font: "Arial",
            }),
          ],
        }),
        new Paragraph({
          alignment: AlignmentType.RIGHT,
          children: [
            new TextRun({
              children: [PageNumber.CURRENT],
            }),
          ],
        }),
      ],
    }),
    header: new Header({
      children: [
        new Paragraph({
          spacing: {
            before: 0,
            after: 550,
          },
          alignment: AlignmentType.RIGHT,
          children: [
            new ImageRun({
              data: soldatiLogo,
              transformation: {
                width: 100.69,
                height: 43,
              },
            }),
          ],
        }),
      ],
    }),
  },
  soldatiConecta: {
    font: "Arial",
    titleSize: 21,
    textSize: 18,
    lineSpacing: 310,
    marginRight: 880,
    marginLeft: 880,
    beforeParagraph: 300,
    footer: new Footer({
      children: [
        new Paragraph({
          spacing: {
            before: 300,
            after: 0,
          },
          alignment: AlignmentType.CENTER,
          children: [
            new TextRun({
              text: "MERCADO RESIDENCIAL – EMPRENDIMIENTOS E INVERSIONES – MERCADO INTERNACIONAL",
              size: 13,
              font: "Arial",
            }),
          ],
        }),
        new Paragraph({
          spacing: {
            before: 60,
            after: 200,
          },
          alignment: AlignmentType.CENTER,
          children: [
            new TextRun({
              text: "soldati.com / documento creado en andesdocs.com",
              size: 15,
              font: "Arial",
            }),
          ],
        }),
        new Paragraph({
          alignment: AlignmentType.RIGHT,
          children: [
            new TextRun({
              children: [PageNumber.CURRENT],
            }),
          ],
        }),
      ],
    }),
    header: new Header({
      children: [
        new Paragraph({
          spacing: {
            before: 0,
            after: 550,
          },
          alignment: AlignmentType.RIGHT,
          children: [
            new ImageRun({
              data: soldatiConectaLogo,
              transformation: {
                width: 100.69,
                height: 43,
              },
            }),
          ],
        }),
      ],
    }),
  },

  izr: {
    font: "Arial",
    titleSize: 21,
    textSize: 21,
    lineSpacing: 470,
    marginRight: 780,
    marginLeft: 780,
    beforeParagraph: 300,
    footer: new Footer({
      children: [
        new Paragraph({
          spacing: {
            before: 160,
            after: 200,
          },
          alignment: AlignmentType.CENTER,
          children: [
            new TextRun({
              text: "www.izr.com.ar / Documento creado en Andesdocs.com",
              size: 15,
              font: "Arial",
            }),
          ],
        }),
      ],
    }),
    header: new Header({
      children: [
        new Paragraph({
          spacing: {
            before: 0,
            after: 450,
          },
          alignment: AlignmentType.RIGHT,
          children: [
            new ImageRun({
              data: izrLogo,
              transformation: {
                width: 200,
                height: 86,
              },
            }),
          ],
        }),
      ],
    }),
  },

  clickAparts: {
    font: "Arial",
    titleSize: 21,
    textSize: 21,
    lineSpacing: 470,
    marginRight: 780,
    marginLeft: 780,
    beforeParagraph: 300,
    header: new Header({
      children: [
        new Paragraph({
          spacing: {
            before: 0,
            after: 550,
          },
          alignment: AlignmentType.RIGHT,
          children: [
            new ImageRun({
              data: clickApartsLogo,
              transformation: {
                width: 100.69,
                height: 43,
              },
            }),
          ],
        }),
      ],
    }),
  },
  toribioAchaval: {
    font: "Arial",
    titleSize: 21,
    textSize: 21,
    lineSpacing: 470,
    marginRight: 780,
    marginLeft: 780,
    beforeParagraph: 300,
    footer: new Footer({
      children: [
        new Paragraph({
          spacing: {
            before: 300,
            after: 0,
          },
          alignment: AlignmentType.CENTER,
          children: [
            new TextRun({
              text: "Toribio Achával",
              size: 13,
              font: "Arial",
            }),
          ],
        }),
        new Paragraph({
          spacing: {
            before: 60,
            after: 200,
          },
          alignment: AlignmentType.CENTER,
          children: [
            new TextRun({
              text: "Documento creado en andesdocs.com",
              size: 15,
              font: "Arial",
            }),
          ],
        }),
      ],
    }),
    header: new Header({
      children: [
        new Paragraph({
          spacing: {
            before: 0,
            after: 550,
          },
          alignment: AlignmentType.RIGHT,
          children: [
            new ImageRun({
              data: toribioAchavalLogo,
              transformation: {
                width: 100.69,
                height: 43,
              },
            }),
          ],
        }),
      ],
    }),
  },
  flechaBus: {
    font: "Times New Roman",
    titleSize: 25,
    textSize: 21,
    lineSpacing: 440,
    marginRight: 880,
    marginLeft: 880,
    beforeParagraph: 300,
    header: new Header({
      children: [
        new Paragraph({
          spacing: {
            before: 0,
            after: 550,
          },
          alignment: AlignmentType.RIGHT,
          children: chooseFlechaBusLogo(),
        }),
      ],
    }),
  },
  andesMar: {
    font: "Arial",
    titleSize: 21,
    textSize: 21,
    lineSpacing: 400,
    marginRight: 780,
    marginLeft: 780,
    beforeParagraph: 300,
    footer: new Footer({
      children: [
        new Paragraph({
          spacing: {
            before: 300,
            after: 0,
          },
          alignment: AlignmentType.CENTER,
          children: [
            new TextRun({
              text: "Andesmar",
              size: 13,
              font: "Arial",
            }),
          ],
        }),
        new Paragraph({
          spacing: {
            before: 60,
            after: 200,
          },
          alignment: AlignmentType.CENTER,
          children: [
            new TextRun({
              text: "documento creado en andesdocs.com",
              size: 15,
              font: "Arial",
            }),
          ],
        }),
      ],
    }),
    header: new Header({
      children: [
        new Paragraph({
          spacing: {
            before: 0,
            after: 550,
          },
          alignment: AlignmentType.RIGHT,
          children: [
            new TextRun({
              text: "",
              size: 13,
              font: "Arial",
            }),
          ],
        }),
      ],
    }),
  },
  fabian: {
    font: "Arial",
    titleSize: 21,
    textSize: 21,
    lineSpacing: 340,
    marginRight: 1580,
    marginLeft: 1580,
    beforeParagraph: 300,
    footer: new Footer({
      children: [
        new Paragraph({
          spacing: {
            before: 300,
            after: 0,
          },
          alignment: AlignmentType.CENTER,
          children: [
            new TextRun({
              text: "",
              size: 13,
              font: "Arial",
            }),
          ],
        }),
        new Paragraph({
          spacing: {
            before: 60,
            after: 200,
          },
          alignment: AlignmentType.CENTER,
          children: [
            new TextRun({
              text: "Av. Alvear 1890, p. 12 (C1129AAN) Cdad Autónoma de Buenos Aires. Tel: +54 11 4807-2345 mail: info@fabianachaval.com",
              size: 15,
              font: "Arial",
            }),
          ],
        }),
      ],
    }),
    header: new Header({
      children: [
        new Paragraph({
          spacing: {
            before: 0,
            after: 550,
          },
          alignment: AlignmentType.RIGHT,
          children: [
            new ImageRun({
              data: fabianLogo,
              transformation: {
                width: 100.69,
                height: 43,
              },
            }),
          ],
        }),
      ],
    }),
  },
  guimat: {
    font: "Arial",
    titleSize: 20,
    textSize: 20,
    lineSpacing: 280,
    marginRight: 780,
    marginLeft: 780,
    beforeParagraph: 160,
    header: new Header({
      children: [
        new Paragraph({
          spacing: {
            before: 0,
            after: 550,
          },
          alignment: AlignmentType.CENTER,
          children: [
            new ImageRun({
              data: guimatLogo,
              transformation: {
                width: 230,
                height: 97.26,
              },
            }),
          ],
        }),
      ],
    }),
    footer: new Footer({
      children: [
        new Paragraph({
          spacing: {
            before: 300,
            after: 0,
          },
          alignment: AlignmentType.CENTER,
          children: [
            new TextRun({
              text: "GUIMAT PROPIEDADES   –   Junín 1144 (1113) CABA   –   www.guimat.com",
              size: 15,
              font: "Arial",
              color: "#e21b1a",
              bold: true,
            }),
          ],
        }),
        new Paragraph({
          spacing: {
            before: 60,
            after: 260,
          },
          alignment: AlignmentType.CENTER,
          children: [
            new TextRun({
              text: "CUCICBA Mat 197 / 6933  –  CMCPSI Mat 7060  –  Tel: 4821-1010   –   e-mail: info@guimat.com",
              size: 15,
              font: "Arial",
              color: "#e21b1a",
              bold: true,
            }),
          ],
        }),
        new Paragraph({
          spacing: {
            before: 0,
            after: 0,
          },
          alignment: AlignmentType.RIGHT,
          children: [
            new ImageRun({
              data: guimatIso,
              transformation: {
                width: 35,
                height: 35,
              },
            }),
          ],
        }),
      ],
    }),
  },
};

export default null;

export const interpolateAnswers = (
  document,
  answers,
  companyName = null,
  removeHeaderAndFooter = false
) => {
  if (
    companyName &&
    (companyName === "soldati" ||
      companyName === "soldatiConecta" ||
      companyName === "clickAparts" ||
      companyName === "fabian" ||
      companyName === "toribioAchaval" ||
      companyName === "flechaBus" ||
      companyName === "guimat" ||
      companyName === "izr" ||
      companyName === "andes" ||
      companyName === "andesMar")
  ) {
    font = stylesPerCompany[companyName].font;
    titleSize = stylesPerCompany[companyName].titleSize;
    textSize = stylesPerCompany[companyName].textSize;
    lineSpacing = stylesPerCompany[companyName].lineSpacing;
    marginRight = stylesPerCompany[companyName].marginRight;
    marginLeft = stylesPerCompany[companyName].marginLeft;
    beforeParagraph = stylesPerCompany[companyName].beforeParagraph || 300;
    if (stylesPerCompany[companyName].footer && !removeHeaderAndFooter) {
      footer = stylesPerCompany[companyName].footer;
    }
    if (stylesPerCompany[companyName].header && !removeHeaderAndFooter) {
      header = stylesPerCompany[companyName].header;
    }
  }

  const interpolatedArray = document.map((e) => {
    // this constant will be turned to true if the whole paragrapgh needs to be removed
    let removed = false;
    // this constant will be the paragraph that is returned inside
    // the Text component
    //

    const paragraph = e.map((textObject) => {
      const oldObject = textObject;
      //
      // We use a try catch so that if there is Any answers misisng
      // the code wont break and will just remove the paragraph
      //
      try {
        //
        let text;
        //
        // This eval line is crucial as it will interpolate the
        // answers with paragrphs
        //
        if (textObject.stopRemoval) {
          try {
            text = eval("`" + textObject.text + "`");
          } catch {
            text = "";
          }
        } else {
          text = eval("`" + textObject.text + "`");
        }
        //
        //
        // this will check if the answer is a text array and will iterate over it
        //
        //
        //
        if (textObject.type === "textArray") {
          const oldText = text;
          const arraySpreaded = answers[textObject.question.section][
            textObject.question.number
          ].answer
            .join(", ")
            .toLowerCase();
          if (arraySpreaded.trim() === "") {
            text = "";
            if (!textObject.stopRemoval || textObject.stopRemoval === false) {
              removed = true;
            }
          } else {
            text = oldText.replace("{{array}}", arraySpreaded);
          }
        }
        //
        //
        //
        //
        //

        if (
          textObject.depend &&
          textObject.question.answer !==
            answers[textObject.question.section][textObject.question.number]
              .answer
        ) {
          text = "";
          // this will remove the whole praragraph unless specified
          if (!textObject.stopRemoval || textObject.stopRemoval === false) {
            removed = true;
          }
        }
        //
        //
        //
        //
        //
        //

        // this checks wheteher the sentence or text depends on not beign specific answer
        //and if it does and that answer is  what it needs not to be it removes it
        //
        //
        //
        //
        //
        //

        if (
          textObject.notBe &&
          textObject.question.answer ===
            answers[textObject.question.section][textObject.question.number]
              .answer
        ) {
          text = "";
          if (!textObject.stopRemoval || textObject.stopRemoval === false) {
            removed = true;
          }
        }
        //
        //
        //
        //
        //
        //
        //
        //

        // this will check wether there is any matches on multiple options selections
        //INCLUDES
        //
        //
        if (textObject.includes && !textObject.notInclude) {
          const includedAnswers = textObject.includes.map((e) => {
            if (
              // this line will check if the answer is an array so that it can iterate it
              Array.isArray(
                answers[textObject.question.section][textObject.question.number]
                  .answer
              )
            ) {
              // here we check if the array of answers contains the specified answer
              return answers[textObject.question.section][
                textObject.question.number
              ].answer.includes(e);
            }
          });
          // if any of the answers isnt found it will return a false
          //which will indicate that we shall remove the text
          if (includedAnswers.includes(false)) {
            text = "";
            if (!textObject.stopRemoval || textObject.stopRemoval === false) {
              removed = true;
            }
          }
        }
        //
        //
        //
        //
        //
        //
        //
        //
        // This will check wether there are any answers that should NOT
        // be included to include the text into the document
        //
        //
        //
        if (textObject.notInclude && !textObject.includes) {
          const notIncludedAnswers = textObject.notInclude.map((e) => {
            if (
              // this line will check if the answer is an array so that it can iterate it
              Array.isArray(
                answers[textObject.question.section][textObject.question.number]
                  .answer
              )
            ) {
              // here we check if the array of answers contains the specified answer
              return !answers[textObject.question.section][
                textObject.question.number
              ].answer.includes(e);
            }
          });
          // if any of the answers isnt found it will return a false
          //which will indicate that we shall remove the text
          if (notIncludedAnswers.includes(false)) {
            text = "";
            if (!textObject.stopRemoval || textObject.stopRemoval === false) {
              removed = true;
            }
          }
        }
        //
        //
        //
        //
        //
        //
        // This will check cases for BOTH conditions
        //
        //
        //
        //
        //
        if (textObject.notInclude && textObject.includes) {
          const includedAnswers2 = textObject.includes.map((e) => {
            if (
              // this line will check if the answer is an array so that it can iterate it
              Array.isArray(
                answers[textObject.question.section][textObject.question.number]
                  .answer
              )
            ) {
              // here we check if the array of answers contains the specified answer
              return answers[textObject.question.section][
                textObject.question.number
              ].answer.includes(e);
            }
          });
          // if any of the answers isnt found it will return a false
          //which will indicate that we shall remove the text
          if (includedAnswers2.includes(false)) {
            text = "";
            if (!textObject.stopRemoval || textObject.stopRemoval === false) {
              removed = true;
            }
          } else {
            //
            // this is where if the first part of the checking is passed
            // the second will start and check the NOT Included
            const NotIncludedAnswers2 = textObject.notInclude.map((e) => {
              // here we check if the array of answers contains the specified answer
              return !answers[textObject.question.section][
                textObject.question.number
              ].answer.includes(e);
            });
            if (NotIncludedAnswers2.includes(false)) {
              text = "";
              if (!textObject.stopRemoval || textObject.stopRemoval === false) {
                removed = true;
              }
            }
          }
        }
        //
        //
        //
        //
        //
        return {
          ...textObject,
          text: text,
        };
      } catch {
        removed = true;
        return "";
      }
    });
    if (removed) {
      return null;
    }
    return paragraph;
  });

  const arrayWithFilteredNulls = interpolatedArray.filter((e) => {
    return Array.isArray(e);
  });

  const mapped = arrayWithFilteredNulls.map((e) => {
    const arrayWithBoldSeparated = e.map((b) => {
      if (
        b.type === "text" &&
        b.text?.includes("$$") &&
        b.text?.includes("^^")
      ) {
        const words = b.text.split("$$");
        const arrayOfSplitStringsWithSymbol = words.map((t) => {
          if (t.includes("^^")) {
            const processed = t.replaceAll("^^", "");
            return {
              type: "bold",
              text: processed,
            };
          }
          return {
            type: "text",
            text: t,
          };
        });
        return arrayOfSplitStringsWithSymbol;
      }
      return b;
    });

    const flatArrayWithBoldSeparated = arrayWithBoldSeparated.flat();

    const arrayOfChildren = flatArrayWithBoldSeparated.map((b) => {
      if (typeof b.text === "string") {
        if (b.type === "title") {
          return new TextRun({
            text: b.text,
            bold: true,
            size: titleSize,
            font: font,
            underline: {
              type: UnderlineType.SINGLE,
              color: "000000",
            },
          });
        }
        if (b.type === "bold" || b.type === "subtitle") {
          return new TextRun({
            text: b.text,
            size: textSize,
            bold: true,
            font: font,
          });
        }
        if (b.type === "boldRight") {
          return new TextRun({
            text: b.text,
            size: textSize,
            bold: true,
            font: font,
          });
        }
        if (b.type === "bullet") {
          return new TextRun({
            text: `${b.bullet} ${b.text}`,
            size: textSize,
            bullet: true,
            font: font,
          });
        }
        return new TextRun({
          text: b.text,
          size: textSize,
          font: font,
        });
      } else {
        return new TextRun("");
      }
    });

    const indent = e[0].bullet ? 720 : 0;
    let align =
      e[0].type === "title" ? AlignmentType.CENTER : AlignmentType.JUSTIFIED;

    if (e[0].type === "boldRight") {
      align = AlignmentType.RIGHT;
    }

    return new Paragraph({
      children: [...arrayOfChildren],
      spacing: {
        before: beforeParagraph,
        line: lineSpacing,
      },
      indent: {
        left: indent,
      },
      alignment: align,
    });
  });

  const doc = new Document({
    sections: [
      {
        properties: {
          page: {
            margin: {
              top: 0,
              right: marginRight,
              bottom: 0,
              left: marginLeft,
            },
          },
        },
        headers: {
          default: header,
        },
        footers: {
          default: footer,
        },
        children: [...mapped],
      },
    ],
  });

  return doc;
};
