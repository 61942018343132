import { useEffect, useState } from "react";
import DocumentsManager from "./DocumentsManager/DocumentsManager";
import { Route, useLocation } from "react-router-dom";
import DashboarSectionTitle from "./DashboardSectionTitle/DashboardSectionTitle";
import DashboardSummary from "./DashboardSummary/DashboardSummary";
import DashboardSelector from "../DocumentSelector/DashboardSelector/DashboardSelector";
import Profile from "../../Authentication/Profile/Profile";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import { setLoading } from "../../Utils/modalMethods";
import NoDocumentsCreated from "./DocumentsManager/NoDocumentsCreated/NoDocumentsCreated";
import ElectronicSignature from "./ElectronicSignature/ElectronicSignature";
import Alarms from "../Alarms/Alarms.screen";
import { useSelector } from "react-redux";
import DocxEditorMain from "../../components/DocxEditor/DocxEditorMain";
import { getMasterDataAndUser } from "../../Utils/getMasterData";
import DashboardEditSelector from "../DocumentSelector/DashboardEditSelector/DashboarEditSelector";
import Tutorials from "./Tutorials/Tutorials";
import UsersManagement from "./UsersManagement/UsersManagement";
import { ProfilePicAlertModal } from "../../uiComponents/ProfilePic/ProfilePicAlertModal";
import UsersManagementDeIn from "./UsersManagement/UsersManagementDeIn";
import { APIGetWithError } from "../../Services/authenticated";

import "./styles.css";
import { LeftBar } from "./LeftBar/LeftBar";
import { ESignersDataLinks } from "../ESignersDataLinks/ESignersDataLinks";
import { Box } from "@mui/material";
import { useAtomValue } from "jotai";
import { documentsAtom } from "../../jotai/documents.jotai";
import { RootState } from "../../redux/rootReducer";
import { User } from "../../Types/User.types";
import AlarmsModal from "../../components/Modals/AlarmsModal/AlarmsModal";

const Dashboard = () => {
  const [userData, setuserData] = useState(null);
  const { companyDocuments, spaceUsage, company } = useSelector(
    (state: RootState) => state.masterData
  );

  const { documents } = useAtomValue(documentsAtom);

  const reduxUser: User | null = useSelector(
    (state: RootState) => state.masterData.user
  );
  const [hasDeInAccess, setHasDeInAccess] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isUserLoaded, setIsUserLoaded] = useState(false);
  const [isProfilePic, setIsProfilePic] = useState(false);
  const history = useHistory();
  const { user, getAccessTokenSilently, logout, isLoading, loginWithRedirect } =
    useAuth0();

  const [notifyAlarms, setNotifyAlarms] = useState(false);

  const location = useLocation();

  const loadMasterData = async () => {
    setLoading(true);
    try {
      const accesToken = await getAccessTokenSilently();

      const masterDataResult = await getMasterDataAndUser(
        accesToken,
        user,
        history,
        logout
      );

      if (!masterDataResult) {
        history.push("/");
        return;
      }

      const checkAdminAccess = async () => {
        const accessToken = await getAccessTokenSilently();
        const response = await APIGetWithError(
          "/users/restrict-user-management",
          accessToken
        );

        if (response) {
          setIsAdmin(response.access);
        }
      };

      const checkDeInAccess = async () => {
        const accessToken = await getAccessTokenSilently();
        const response = await APIGetWithError(
          "/users/validate-deinmo-access",
          accessToken
        );

        if (response) {
          setHasDeInAccess(response.hasAccess);
        }
      };

      checkAdminAccess();
      checkDeInAccess();
    } finally {
      setLoading(false);
    }
  };

  const checkAndSetNotifyAlarms = () => {
    const today = new Date().toLocaleDateString();
    const lastVisit = localStorage.getItem("lastVisit");

    if (!lastVisit || lastVisit !== today) {
      localStorage.setItem("lastVisit", today);
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (!isLoading && !user) {
      loginWithRedirect();
    }
    if (user) {
      const meta = user["https://andesdocs.com/meta2"].metadata;
      setuserData(meta);
      loadMasterData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isLoading]);

  useEffect(() => {
    if (reduxUser !== null) {
      setIsUserLoaded(true);
      if (
        (reduxUser.photoUrl && reduxUser.photoUrl !== "<empty>") ||
        reduxUser.photoUrl !== ""
      ) {
        setIsProfilePic(false);
      } else {
        setIsProfilePic(true);
      }
    } else {
      setIsProfilePic(true);
    }
  }, [reduxUser]);

  useEffect(() => {
    const notify = checkAndSetNotifyAlarms();
    setNotifyAlarms(notify);
  }, []);

  const handleCloseAlarmsModal = () => {
    setNotifyAlarms(false);
  };

  const handleCloseAlertPicModal = () => {
    setIsProfilePic(false);
  };

  return (
    <Box className="dashboard-max-window-size b-tertiary">
      {isUserLoaded && isProfilePic ? (
        <ProfilePicAlertModal
          open={isProfilePic}
          onClose={handleCloseAlertPicModal}
        />
      ) : (
        // notifyAlarms &&
        notifyAlarms &&
        documents &&
        documents.length > 0 && <AlarmsModal onClose={handleCloseAlarmsModal} />
      )}
      <div className="dashboard-no-show-mobile b-tertiary">
        {userData && company && (
          <div className="dashboard-wrapper">
            <LeftBar isAdmin={isAdmin} />
            <div
              className={`dashboard-wrapper-box b-tertiary ${
                location.pathname.includes("docx-editor")
                  ? "dashboard-wrapper-box-docx-editor"
                  : "null"
              }`}
            >
              <Route path="/dashboard/finished">
                <DashboarSectionTitle
                  title="Documentos en la Nube"
                  subMessage={`Mostrando ${documents.length || 0} documentos`}
                />
                {documents && documents.length > 0 && (
                  <DocumentsManager
                    documents={documents}
                    spaceUsage={spaceUsage}
                  />
                )}
                {documents && documents.length < 1 && <NoDocumentsCreated />}
              </Route>
              <Route path="/dashboard/documents">
                <DashboarSectionTitle title="Generar nuevo documento" />
                <DashboardSelector companyDocuments={companyDocuments} />
              </Route>

              <Route path="/dashboard/editor">
                <DashboarSectionTitle title="Editar generador de documento" />
                <DashboardEditSelector
                  companyDocuments={companyDocuments ?? []}
                />
              </Route>

              <Route path="/dashboard/esignature">
                <DashboarSectionTitle
                  title="Firma Electrónica"
                  subMessage="Visualización y creación de firmas electrónicas"
                />
                <ElectronicSignature documents={documents} company={company} />
              </Route>
              <Route path="/dashboard/alarms">
                <DashboarSectionTitle
                  title="Alarmas / Vencimientos"
                  subMessage="Estado y visualización de vencimientos proximos"
                />
                <Alarms />
              </Route>
              <Route path="/dashboard/profile">
                <DashboarSectionTitle title="Mi Perfil" />
                <DashboardSummary
                  companyDocumentsLength={companyDocuments?.length}
                  documentsLength={documents?.length}
                  spaceUsage={spaceUsage}
                />
                <div className="dashboard-profile-warpper">
                  <Profile />
                </div>
              </Route>
              <Route path="/dashboard/docx-editor">
                <DocxEditorMain />
              </Route>
              <Route path="/dashboard/tutorials">
                <DashboarSectionTitle title="Tutoriales" />
                <Tutorials />
              </Route>

              <Route path="/dashboard/EsignersDataLinks">
                <ESignersDataLinks />
              </Route>

              <Route path="/dashboard/usersManagement">
                {hasDeInAccess ? <UsersManagementDeIn /> : <UsersManagement />}
              </Route>
            </div>
          </div>
        )}
      </div>
      <div className="dashboard-show-mobile">
        <h1 style={{ marginTop: 40 }}>Por favor, agrande el Navegador</h1>
        <p style={{ marginTop: 50, padding: 50 }}>
          Para visualizar el Dashboard abra Andes Docs en pantalla completa en
          una computadora. Si aún no ve el dashboard establezca el zoom del
          navegador en 100% o contactese con soporte.
        </p>
      </div>
    </Box>
  );
};

export default Dashboard;
