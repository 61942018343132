import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Tooltip,
  Typography,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import React from "react";
import { styled } from "@mui/system";
import { colorPallette } from "../../Common/theme";
import { faFaceAngry } from "@fortawesome/free-solid-svg-icons";

interface AndesTooltipProps {
  title?: string;
  description?: string;
  icon?: IconProp;
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
  children?: React.ReactNode;
  color?: string;
  sx?: React.CSSProperties; // Añadir sx como prop
}

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: colorPallette.dark,
    color: colorPallette.tertiary,
    fontSize: "1rem",
    borderRadius: "4px",
    opacity: 1,
    transition: "opacity 0.3s ease-in-out, transform 0.3s ease-in-out",
    zIndex: 9999,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: colorPallette.dark,
  },
}));

export const AndesTooltip: React.FC<AndesTooltipProps> = ({
  title,
  description,
  icon,
  placement = "top",
  children,
  color,
  sx,
}) => {
  return (
    <CustomTooltip
      title={
        <>
          {title && (
            <Typography color="inherit" fontWeight="Bold" pb={1}>
              {title}
            </Typography>
          )}
          <Typography variant="body2">{description}</Typography>
        </>
      }
      placement={placement}
      arrow
    >
      <span style={{ ...sx }}>
        {children ? (
          children
        ) : (
          <FontAwesomeIcon icon={icon || faFaceAngry} color={color} />
        )}
      </span>
    </CustomTooltip>
  );
};
