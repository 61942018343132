import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { APIPostWithError } from "../../../Services/authenticated";
import { setAppError, setLoading } from "../../../Utils/modalMethods";
import { useForm } from "react-hook-form";
import { useAtomValue } from "jotai";
import {
  commonComponentAtom,
  resetCommonComponent,
  setCommonComponents,
} from "../../../jotai/commonComponentAtom.jotai";

import AndesModal from "../../../uiComponents/AndesModal/AndesModal";
import AutomaticForm, { Field } from "../../../uiComponents/Form/AutomaticForm";
import { Box } from "@mui/material";

const SignerEmailModal: React.FC = () => {
  const { currentSigner } = useAtomValue(commonComponentAtom);
  const { control, handleSubmit, setError } = useForm({
    defaultValues: { email: currentSigner?.email || "" },
  });
  const { getAccessTokenSilently } = useAuth0();

  const fields: Field[] = [
    {
      name: "email",
      label: "Email",
      type: "text",
      required: true,
    },
  ];

  const closeModal = () => resetCommonComponent();

  const updateSignerEmail = async (data: { email: string }) => {
    if (!data.email.includes("@") || !data.email.includes(".")) {
      setError("email", {
        type: "manual",
        message: "El email debe tener un formato válido.",
      });
      return;
    }

    try {
      setLoading(true);
      const accessToken = await getAccessTokenSilently();
      const res = await APIPostWithError(
        "/electronic-signature/update-signature",
        accessToken,
        {
          signerToken: currentSigner?.token,
          email: data.email,
        }
      );
      if (res.signatureUpdated) {
        setCommonComponents({
          successModal: {
            message: "Email actualizado exitosamente",
            submessage: "",
            disclaimer: "",
            show: true,
          },
        });
      } else {
        setAppError();
        closeModal();
      }
    } catch (error) {
      setAppError("Error al actualizar el nuevo Email");
    } finally {
      setLoading(false);
    }
  };

  return (
    <AndesModal
      message="Modificar email del firmante"
      handleClose={closeModal}
      handleSubmit={handleSubmit(updateSignerEmail)}
      submitButtonText="Guardar"
    >
      <Box mt={2} width={"100%"}>
        <AutomaticForm fields={fields} control={control} />
      </Box>
    </AndesModal>
  );
};

export default SignerEmailModal;
