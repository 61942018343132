import { useHistory } from "react-router-dom";
import Button from "../../uiComponents/Button/Button";
import { Stack } from "@mui/material";
import { gradients } from "../../Common/theme";

const PrevUserExistant = () => {
  let history = useHistory();

  return (
    <Stack
      sx={{
        background: gradients.dark,
        display: "flex",
        justifyContent: "center",
        position: "relative",
        pb: { xs: 10, sm: 20, md: 15, lg: 10 },
        height: "100vh",
      }}
    >
      <h1 className="margin-v-l">
        Usted ya posee una cuenta en
        <span className="primary"> Andes Docs</span> con este mismo email
      </h1>
      <p className="margin-v">
        Si ingreso con google pruebe ingresar con email y contraseña. Si ingreso
        con Email y contraseña pruebe ingresar con google. Si presiste el
        problema contactese con soporte
      </p>

      <p className="margin-v"></p>
      <Button
        label="Contactar Soporte"
        onClick={() => {
          history.push("/contacto");
        }}
        color="secondary"
        center
      />
      <Button
        label="Reintentar"
        onClick={() => {
          history.push("/");
        }}
        color="secondary"
        center
      />
    </Stack>
  );
};

export default PrevUserExistant;
