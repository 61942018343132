import React from "react";
import { connect } from "react-redux";
import { useAtomValue } from "jotai";
import { confirmationModalAtom } from "../../jotai/confimationModalAtom.jotai";
import { commonComponentAtom } from "../../jotai/commonComponentAtom.jotai";
import LoadingModal from "../../components/Modals/LoadingModal/LoadingModal";
import ExpirationModal from "../../components/Modals/ExpirationModal/ExpirationModal";
import ErrorModal from "../../components/Modals/ErrorModal/ErrorModal";
import FolderNameModal from "../../components/Modals/FolderNameModal/FolderNameModal";
import SuccessModal from "../../components/Modals/SuccessModal/SuccessModal";
import FileUploadModal from "../../components/Modals/FileUploadModal/FileUploadModal";
import SignatureStatusModal from "../../components/Modals/SignatureStatusModal/SignatureStatusModal";
import SignerEmailModal from "../../components/Modals/SignerEmailModal/SignerEmailModal";
import DocumentDetailModal from "../../components/Modals/DocumentDetailModal/DocumentDetailModal";
import FolderModal from "../../components/Modals/FolderModal/FolderModal";
import NewFolderModal from "../../components/Modals/NewFolderModal/NewFolderModal";
import JotaiConfirmModal from "../../components/Modals/JotaiConfirmModal/JotaiConfirmModal";

const CommonCompoentRender: React.FC<any> = ({ modal }) => {
  const confirmationModalState = useAtomValue(confirmationModalAtom);
  const commonComponentState = useAtomValue(commonComponentAtom);

  return (
    <div>
      {/* // loading moverlo a jotai */}
      {modal.loadingModal.show && <LoadingModal />}
      {commonComponentState.showExpirationModal && <ExpirationModal />}
      {commonComponentState.showFolderNameModal && <FolderNameModal />}
      {commonComponentState.showFileUploadModal && <FileUploadModal />}
      {commonComponentState.showSignatureStatusModal && (
        <SignatureStatusModal />
      )}
      {commonComponentState.showSignerEmailModal && <SignerEmailModal />}
      {commonComponentState.showDocumentDetailModal && <DocumentDetailModal />}
      {commonComponentState.showFolderModal && <FolderModal />}
      {commonComponentState.showNewFolderModal && <NewFolderModal />}
      {confirmationModalState.show && <JotaiConfirmModal />}
      {commonComponentState.successModal.show && <SuccessModal />}
      {commonComponentState.errorModal.show && <ErrorModal />}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return { modal: state.modal };
};

export default connect(mapStateToProps, null)(CommonCompoentRender);
