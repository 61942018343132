import { atom, getDefaultStore } from "jotai";

export type Document = {
  amount?: string;
  andesDockerDoc: boolean;
  approvedBy: string;
  comments: string;
  companyId: string;
  companyName: string;
  createdBy: string;
  creatorPhotoUrl: string;
  dateCreated: string;
  delFlg: number;
  description: string;
  documentId: string;
  documentType: string;
  expirationDate?: string;
  fileName: string;
  filePath: string;
  folder?: string | null;
  format: string;
  part1?: string | null;
  part2?: string | null;
  part3?: string | null;
  part4?: string | null;
  size: number;
  status: string;
  uniqueKey: string;
  updateDate: string;
  userId: string;
  versionId: string;
  versionNumber: number;
  daysToExpiration?: number;
};

type DocumentsAtomType = {
  documents: Document[];
};

const store = getDefaultStore();

export const initialDocumentsState: DocumentsAtomType = {
  documents: [],
};

export const documentsAtom = atom<DocumentsAtomType>(initialDocumentsState);

export const setDocumentsAtom = (params: Document[]) => {
  store.set(documentsAtom, {
    documents: params,
  });
};

export const getDocumentsAtom = () => {
  return store.get(documentsAtom);
};

export const updateDocumentOnAtom = (documentToUpdate: Document) => {
  const { documentId } = documentToUpdate;
  const { documents } = store.get(documentsAtom);

  if (documents) {
    const updatedDocuments = documents.map((doc: any) =>
      doc.documentId === documentId ? { ...documentToUpdate } : doc
    );
    setDocumentsAtom(updatedDocuments);
  }
};

export const removeDocumentFromAtom = (documentToRemove: Document) => {
  const { documentId } = documentToRemove;
  const { documents } = store.get(documentsAtom);

  if (documents) {
    const filteredDocuments = documents.filter(
      (doc: any) => doc.documentId !== documentId
    );
    setDocumentsAtom(filteredDocuments);
  }
};

export const insertDocumentToAtom = (newDocument: Document) => {
  const { documents } = store.get(documentsAtom);

  const updatedDocuments = documents
    ? [...documents, newDocument]
    : [newDocument];
  setDocumentsAtom(updatedDocuments);
};
