import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { setLoading } from "../../../Utils/modalMethods";
import AndesModal from "../../../uiComponents/AndesModal/AndesModal";
import { colorPallette } from "../../../Common/theme";
import AutomaticForm, { Field } from "../../../uiComponents/Form/AutomaticForm";

type EditUserModalProps = {
  user: any;
  isOpen: boolean;
  onClose: () => void;
  onSave: (updatedUser: any) => void;
};

const roleOptions = [
  { value: "admin", label: "Admin" },
  { value: "user", label: "User" },
];

const documentShareOptions = [
  { value: "true", label: "Todos los documentos de la empresa" },
  { value: "false", label: "Solo los documentos del usuario" },
];

const EditUserModal: React.FC<EditUserModalProps> = ({
  user,
  isOpen,
  onClose,
  onSave,
}) => {
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      role: user?.role,
      documentShare: user?.documentShare ? "true" : "false",
    },
  });

  useEffect(() => {
    setValue("role", user?.role);
    setValue("documentShare", user?.documentShare ? "true" : "false");
  }, [user, setValue]);

  const handleSave = async (data: any) => {
    setLoading(true);
    onSave({
      ...user,
      role: data.role,
      documentShare: data.documentShare === "true",
    });
    setLoading(false);
  };

  const fields: Field[] = [
    {
      name: "role",
      label: "Rol",
      type: "options",
      required: true,
      options: roleOptions,
    },
    {
      name: "documentShare",
      label: "Acceso a",
      type: "options",
      required: true,
      options: documentShareOptions,
    },
  ];

  return (
    <AndesModal
      handleClose={onClose}
      message="Editar usuario"
      handleSubmit={handleSubmit(handleSave)}
    >
      <Box
        sx={{
          width: "100%",
          textAlign: "left",
          my: 2,
          fontSize: "1rem",
        }}
      >
        <FontAwesomeIcon
          icon={faUserAlt}
          style={{ color: colorPallette.darkSecondary, marginRight: 8 }}
        />
        {user?.name}
      </Box>
      <AutomaticForm fields={fields} control={control} />
    </AndesModal>
  );
};

export default EditUserModal;
