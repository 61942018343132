import { MODAL_SETTER, MODAL_RESETTER } from "./modal.types";

const INITIAL_STATE = {
  deleteDocumentModal: {
    show: false,
    document: null,
  },
  folderNameModal: {
    show: false,
    folder: null,
  },
  uploadFileModal: {
    show: false,
  },
  loadingModal: {
    show: false,
  },
  errorModal: {
    show: false,
    message: null,
    submessage: null,
    disclaimer: null,
  },
  deleteSignatureModal: {
    show: false,
    signature: null,
  },
  expirationModal: {
    show: false,
    document: null,
    shouldShowSuccess: false,
  },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MODAL_SETTER:
      return {
        ...state,
        ...action.payload,
      };
    case MODAL_RESETTER:
      return INITIAL_STATE;
    default:
      return state;
  }
};
export default reducer;
