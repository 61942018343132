/* eslint-disable react-hooks/exhaustive-deps */
import { Stack, Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import InputForm from "../../uiComponents/Form/FormInput";
import styles from "./ParagraphGeneratorForm.module.css";
import FormSelect from "../../uiComponents/Form/FormSelect";
import { useFieldArray, useForm } from "react-hook-form";
import DependencyForm from "./DocumentDependencyForm";
import DeleteIcon from "../../uiComponents/DeleteIcon/DeleteIcon";
import FormTextarea from "../../uiComponents/Form/FormTextarea";
import { ParagraphOptions } from "../../Types/Document.types";
import { setAppError } from "../../Utils/modalMethods";
import {
  setCurrentParagraphLenth,
  setExpandedInternalDocumentCardId,
  setFormWatch,
  setInternalDocumentSaveOrExpandTrigger,
} from "../../redux/slices/commonComponentsSlice";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { setCommonComponents } from "../../jotai/commonComponentAtom.jotai";

type Props = {
  id: number;
  setReRenderTrigger: any;
};

const ParagraphGeneratorForm: React.FC<Props> = ({
  id,
  setReRenderTrigger,
}) => {
  const onError = () => {
    setAppError(
      "Formulario incompleto",
      "Debe completar todos los campos obligatorios"
    );
  };

  const dispatch = useDispatch();

  const getPrevData = () => {
    return JSON.parse(localStorage.getItem("generator-form-document") || "{}");
  };

  const {
    internalDocumentSaveOrExpandTrigger,
    currentParagraphLength,
    saveDocumentTrigger,
  } = useSelector((state: any) => state.commonComponents);

  const [isInitialRender3, setIsInitialRender3] = useState(true);

  const getDefaultValues = () => {
    if (isInitialRender3) {
      const prevData = getPrevData();
      setIsInitialRender3(false);
      if (!prevData || !prevData.document || !prevData.document[id])
        return { type: "newParagraph" };
      return prevData.document[id];
    }
  };

  const { control, getValues, trigger } = useForm({
    defaultValues: getDefaultValues(),
  });

  const saveData = async (successModal?: boolean, callback?: any) => {
    let isValid = await trigger();
    if (!isValid) {
      onError();
      return false;
    }
    const prevData = getPrevData();
    if (!prevData || !prevData.document) return;
    const currentParagraph = getValues();
    prevData.document[id] = currentParagraph;
    localStorage.setItem("generator-form-document", JSON.stringify(prevData));
    dispatch(setFormWatch(prevData.document));
    if (successModal) {
      setCommonComponents({
        successModal: {
          show: true,
          message: "Guardado Correctamente",
          submessage: "El documento ha sido guardado",
          disclaimer: "",
        },
      });
    }

    if (callback && isValid) callback();
    setReRenderTrigger(false);
    setTimeout(() => setReRenderTrigger(true), 0);
    return true;
  };

  const [isInitialRender2, setIsInitialRender2] = useState(true);

  useEffect(() => {
    if (!isInitialRender2) {
      saveData(true);
    } else {
      setIsInitialRender2(false);
    }
  }, [saveDocumentTrigger]);

  const { fields, remove, append } = useFieldArray({
    control,
    name: `dependencies`,
  });

  const triggerExpansion = async () => {
    if (internalDocumentSaveOrExpandTrigger !== id) {
      const saveStatus = await saveData(false, () =>
        dispatch(
          setExpandedInternalDocumentCardId(internalDocumentSaveOrExpandTrigger)
        )
      );
      if (saveStatus) {
        dispatch(
          setExpandedInternalDocumentCardId(internalDocumentSaveOrExpandTrigger)
        );
      } else {
        dispatch(setInternalDocumentSaveOrExpandTrigger(id));
      }
    }
  };

  const [isInitialRender, setIsInitialRender] = useState(true);

  useEffect(() => {
    if (!isInitialRender) {
      triggerExpansion();
    } else {
      setIsInitialRender(false);
    }
  }, [internalDocumentSaveOrExpandTrigger]);

  function addEmptyObjectAfterIndex<T>(array: T[], index: number): T[] {
    if (index < 0 || index >= array.length) {
      throw new Error("Index out of bounds");
    }

    const newArray = [...array];
    newArray.splice(index + 1, 0, { type: "newParagraph" } as T);
    return newArray;
  }

  const addOneQuestion = async () => {
    await saveData(false, () => {
      dispatch(setExpandedInternalDocumentCardId(9999));
      const prevData = getPrevData().document;
      if (!prevData) return;
      const updatedArray = addEmptyObjectAfterIndex(prevData, id);
      localStorage.setItem(
        "generator-form-document",
        JSON.stringify({ document: updatedArray })
      );
      dispatch(setCurrentParagraphLenth([...currentParagraphLength, 0]));
      dispatch(setExpandedInternalDocumentCardId(id + 1));
      dispatch(setInternalDocumentSaveOrExpandTrigger(id + 1));
    });
  };

  const deleteQuestion = async () => {
    if (currentParagraphLength?.length === 1) {
      setAppError(
        "Error",
        "No puedes eliminar el primer párrafo si hay solo uno"
      );
      return;
    }
    dispatch(setExpandedInternalDocumentCardId(9999));
    const prevData = getPrevData().document;
    if (!prevData) return;
    const updated = [...prevData];
    updated.splice(id, 1);
    localStorage.setItem(
      "generator-form-document",
      JSON.stringify({ document: updated })
    );
    dispatch(setFormWatch(updated));
    const newArray = [...currentParagraphLength];
    newArray.pop();
    dispatch(setCurrentParagraphLenth([...newArray]));
    setTimeout(() => dispatch(setExpandedInternalDocumentCardId(id)), 300);
  };

  return (
    <>
      <Box className={`paragraph_ref_${id}`} mb={5} />
      <Box
        maxWidth="1000px"
        position="relative"
        className={`${styles.card} paragraph_ref_${id}`}
        sx={{
          animation: "scaleIn 0.5s ease-out",
          "@keyframes scaleIn": {
            "0%": {
              transform: "scale(0.97)",
              opacity: 0,
            },
            "100%": {
              transform: "scale(1)",
              opacity: 1,
            },
          },
        }}
      >
        <Stack alignItems="center" mt={1}>
          <Box position="absolute" sx={{ opacity: 0, pointerEvents: "none" }}>
            <InputForm
              control={control}
              name={`id`}
              label="¿id?"
              required
              defaultValue={String(id)}
            />
          </Box>
          <FormSelect
            options={ParagraphOptions}
            control={control}
            name={`type`}
            label="¿Qué tipo de texto es?"
            required
          />
          <FormTextarea
            control={control}
            name={`text`}
            label="Texto a generar:"
            required
            info={[
              'Para VARIABLES utilize {...} // EJ: "El {12} será" ',
              'Para NEGRITA utilize [...] // EJ: "El [Locatario] será" ',
              'Para SUBRAYADO utilize <...> // EJ: "El <Locatario> será" ',
            ]}
            smallText
          />
          <Button onClick={() => saveData()}>Aplicar Texto</Button>

          {fields.map((field, index) => (
            <DependencyForm
              control={control}
              id={index}
              key={field.id}
              remove={remove}
            />
          ))}
          <Button
            onClick={() => {
              append({});
            }}
            sx={{ marginTop: 1, width: 300, color: "#5b55a0" }}
          >
            + Agregar una dependencia
          </Button>

          <DeleteIcon
            label="Eliminar"
            sx={{ alignSelf: "end" }}
            onClick={() => {
              deleteQuestion();
            }}
          />
        </Stack>
      </Box>
      <Box
        component={"button"}
        my={5}
        sx={{
          cursor: "pointer",
          background: "none",
          border: "none",
          "&:hover .hoverClassNmae": {
            opacity: 0.9,
            transform: "scale(1.3) rotate(90deg)",
            transition: "all 0.3s",
          },
        }}
        role="button"
        tabIndex={0}
        onClick={addOneQuestion}
      >
        <FontAwesomeIcon
          className="hoverClassNmae primary mr1"
          icon={faPlusCircle}
        />{" "}
        Agregar un nuevo párrafo
      </Box>
    </>
  );
};

export default ParagraphGeneratorForm;
