import React from "react";
import { faClockFour } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Entries.styles.css";
import { Box } from "@mui/material";
import { colorPallette } from "../../../Common/theme";

type Props = {
  date: string | number;
  onClick?: () => void;
  fallback?: string;
  color?: string;
  hideTime?: boolean;
  fontColor?: string;
  bold?: boolean;
};

const TableDate: React.FC<Props> = ({
  date,
  onClick,
  fallback,
  color,
  hideTime,
  fontColor,
  bold,
}) => {
  if (date === null || !date) {
    return (
      <>
        {fallback && (
          <span style={{ opacity: 0.6 }} className="primary">
            {fallback}
          </span>
        )}
      </>
    );
  }

  const dateToParse = typeof date === "string" ? parseInt(date) : date;

  if (isNaN(dateToParse)) {
    return (
      <>
        {fallback && (
          <span style={{ opacity: 0.6 }} className="primary">
            {fallback}
          </span>
        )}
      </>
    );
  }

  const parsedDate = new Date(dateToParse);

  const displayDate = `${parsedDate.getDate()}/${
    parsedDate.getMonth() + 1
  }/${parsedDate.getFullYear()}`;

  const hours = parsedDate.getHours();

  const minutes = parsedDate.getMinutes();

  const minutesWithZero =
    minutes.toString().length < 2 ? `0${minutes}` : minutes;

  return (
    <div className="relative">
      <div onClick={onClick && onClick}>
        <Box
          sx={{
            color: fontColor || colorPallette.dark,
            fontWeight: bold ? "bold" : "bold",
          }}
          // className={`table-entry-date ${color ? color : "b-dark"}`}\
          className={`table-entry-date`}
        >
          {displayDate}
        </Box>
      </div>
      {!hideTime && (
        <p
          className="table-entry-date-time"
          style={{ color: colorPallette.primary }}
        >
          {hours}:{minutesWithZero} hs
        </p>
      )}
    </div>
  );
};

export default TableDate;
