import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
  faHourglass2,
  faPlus,
  faThumbsUp,
  faThumbsDown,
} from "@fortawesome/free-solid-svg-icons";
import "./TableEntries.styles.css";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { setLoading } from "../../../Utils/modalMethods";
import { APIPostWithError } from "../../../Services/authenticated";
import { updateDocumentOnAtom } from "../../../jotai/documents.jotai";
import { colorPallette } from "../../../Common/theme";

type Props = {
  status: string;
  approvedBy: string;
  document: any;
};

const ApprovalActions: React.FC<any> = ({ document }) => {
  const [showActions, setShowActions] = useState<Boolean>(false);
  const { user } = useSelector((state: RootState) => state.masterData);
  const { getAccessTokenSilently } = useAuth0();

  const handleToggle = async (status: string) => {
    const accessToken = await getAccessTokenSilently();

    let name = user?.name || "";
    const newDocument = { ...document, status, approvedBy: name };
    try {
      setLoading(true);
      const res = await APIPostWithError(
        "/doc-ref/edit-document",
        accessToken,
        newDocument
      );
      if (res) {
        updateDocumentOnAtom(newDocument);
        return true;
      }
    } finally {
      setLoading(false);
    }
    setShowActions(false);
  };

  if (user?.role === "admin" || user?.role === "Admin") {
    return (
      <>
        {showActions ? (
          <div className="table-approve-icon-box b-secondary">
            <FontAwesomeIcon
              icon={faThumbsUp}
              className="white b-primary table-approve-icon"
              onClick={() => {
                handleToggle("approved");
              }}
            />
            <FontAwesomeIcon
              icon={faThumbsDown}
              className="white b-light-red table-approve-icon"
              onClick={() => {
                handleToggle("disapproved");
              }}
            />
            <FontAwesomeIcon
              icon={faTimesCircle}
              className="gray table-approve-icon"
              onClick={() => {
                handleToggle("unapproved");
              }}
            />
          </div>
        ) : (
          <div
            className="table-approve-icon-box-closed b-secondary"
            onClick={() => {
              setShowActions(true);
            }}
          >
            <FontAwesomeIcon icon={faPlus} className="table-plus-icon" />
          </div>
        )}
      </>
    );
  }
  return <></>;
};

const TableEntryAproval: React.FC<Props> = ({
  status,
  approvedBy,
  document,
}) => {
  if (status === "approved")
    return (
      <div className="primary bold table-entry-aproval">
        <FontAwesomeIcon icon={faCheckCircle} className="icon-margin-r" />
        Aprobado
        {approvedBy && (
          <div className="table-entry-aproval-by">Por: {approvedBy}</div>
        )}
        <ApprovalActions document={document} />
      </div>
    );
  if (status === "disapproved")
    return (
      <div className="light-red bold table-entry-aproval">
        <FontAwesomeIcon icon={faTimesCircle} className="icon-margin-r" />
        Desaprobado
        {approvedBy && (
          <div className="table-entry-aproval-by">Por: {approvedBy}</div>
        )}
        <ApprovalActions document={document} />
      </div>
    );

  return (
    <div
      className="table-entry-aproval"
      style={{ color: colorPallette.primary }}
    >
      <span style={{ opacity: 0.5 }}>
        {/* <FontAwesomeIcon icon={faHourglass2} className="icon-margin-r" /> */}
        Sin Aprobar
      </span>
      <ApprovalActions document={document} />
    </div>
  );
};

export default TableEntryAproval;
