import React, { useState } from "react";
import { Button, TextField, Box, Stack } from "@mui/material";
import { setAppError } from "../../Utils/modalMethods";

type Paragraph = {
  type: "newParagraph";
  id: string;
  text: string;
  dependencies: string[];
};

const DocumentStarter: React.FC = () => {
  const [text, setText] = useState("");

  const handleGenerateDocument = () => {
    // Split the text into paragraphs based on every new line
    const paragraphs = text
      .split(/\n+/) // Split on every new line
      .map((line, index) => ({
        type: "newParagraph",
        id: index.toString(),
        text: line.trim(),
        dependencies: [],
      }))
      .filter((paragraph) => paragraph.text.length > 0); // Remove empty lines

    // Convert paragraphs to JSON and save in local storage
    localStorage.setItem(
      "generator-form-document",
      JSON.stringify({ document: paragraphs })
    );
    alert("Documento Generado!");
  };

  const [questions, setQuestions] = useState("");

  const handleGenerateQuestions = () => {
    // Split the text into paragraphs based on every new line
    let hasError = false;

    const paragraphs = questions
      .split(/\n+/) // Split on every new line
      .filter((line) => line.length > 0)
      .map((currentLine, index) => {
        console.log(currentLine);
        const splitIndex = currentLine.indexOf("//");

        if (splitIndex !== -1) {
          let id = currentLine.substring(0, splitIndex).trim();
          let question = currentLine.substring(splitIndex + 2).trim();

          return {
            question: question,
            type: "question",
            section: "1",
            id: id,
            dependencies: [],
          };
        } else {
          hasError = true;
          return {};
        }
      });

    if (!hasError) {
      // Convert paragraphs to JSON and save in local storage
      localStorage.setItem(
        "generator-form-data",
        JSON.stringify({ questions: paragraphs })
      );
      alert("Preguntas Generadas!");
    } else {
      setAppError(
        "Preguntas mal formuladas reviselas",
        'Cada pregunta debe tener un identificador único y un separador "//" seguido de la pregunta.'
      );
    }
  };

  return (
    <Stack alignItems="center" justifyContent="center" width="100vw" my={5}>
      <Stack width="80%" alignItems="center">
        <TextField
          label="Texto del documento"
          multiline
          rows={10}
          variant="outlined"
          //   fullWidth
          value={text}
          onChange={(e) => setText(e.target.value)}
          sx={{ mb: 2, width: "100%" }}
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleGenerateDocument}
        >
          Generar Documento
        </Button>
      </Stack>
      <Stack width="80%" alignItems="center" mt={12}>
        <Box sx={{ mb: 3 }}>
          <Box sx={{ fontWeight: "bold", mb: 3 }}>Formato de preguntas:</Box>
          <p>
            Cada pregunta debe tener un identificador único y un separador "//"
            seguido de la pregunta.
          </p>
          <p>
            Ejemplo: <strong>nombre // ¿Cuál es tu nombre?</strong>
          </p>
        </Box>
        <TextField
          label="Preguntas"
          multiline
          rows={10}
          variant="outlined"
          //   fullWidth
          value={questions}
          onChange={(e) => setQuestions(e.target.value)}
          sx={{ mb: 2, width: "100%" }}
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleGenerateQuestions}
        >
          Generar Preguntas
        </Button>
      </Stack>
    </Stack>
  );
};

export default DocumentStarter;
