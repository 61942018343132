const currency = [
  "Pesos Argentinos",
  "Dólares Estadounidenses",
  "Dólares Estadounidenses billete",
  "Euros",
  "Libras Esterlinas",
  // "Bitcoin",
  // "Ethereum",
];

export default currency;
