import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { BarElement } from "../../../components/BarElement/BarElement";

import "./styles.css";

type Element = {
  name: string;
  icon: string;
  to: string;
};

const elements: Element[] = [
  {
    name: "Generar Nuevo Documento",
    icon: "far fa-file-word",
    to: "/dashboard/documents",
  },
  {
    name: "Documentos en la Nube",
    icon: "fas fa-cloud-download-alt",
    to: "/dashboard/finished",
  },
  {
    name: "Firma Electrónica",
    icon: "fas fa-signature",
    to: "/dashboard/esignature",
  },
  {
    name: "Alarmas / Vencimientos",
    icon: "fas fa-stopwatch-20",
    to: "/dashboard/alarms",
  },
  { name: "Mi Perfil", icon: "far fa-address-card", to: "/dashboard/profile" },
  {
    name: "Editor",
    icon: "fas fa-pencil-alt",
    to: "/dashboard/editor",
  },
  {
    name: "Tutoriales",
    icon: "fas fa-graduation-cap",
    to: "/dashboard/tutorials",
  },
];

type Props = {
  isAdmin: boolean;
};

export const LeftBar: React.FC<Props> = ({ isAdmin }) => {
  const location = useLocation();
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    if (window.location.href.includes("editor")) {
      setSelected(5);
    }
    if (window.location.href.includes("profile")) {
      setSelected(4);
    }
    if (window.location.href.includes("esignature")) {
      setSelected(2);
    }
    if (window.location.href.includes("finished")) {
      setSelected(1);
    }

    if (window.location.href.includes("documents")) {
      setSelected(0);
    }
    if (window.location.href.includes("alarm")) {
      setSelected(3);
    }
    if (window.location.href.includes("tutorials")) {
      setSelected(6);
    }
    if (window.location.href.includes("usersManagement")) {
      setSelected(7);
    }
  }, [location]);

  return (
    <div className="left-bar b-white animate">
      {elements.map((element, index) => {
        return (
          <BarElement
            element={element}
            index={index}
            selected={selected}
            setSelected={setSelected}
            key={index}
          />
        );
      })}

      {isAdmin && (
        <BarElement
          element={{
            name: "Control de Usuarios",
            to: "/dashboard/usersManagement",
            icon: "fas fa-users",
          }}
          index={7}
          setSelected={setSelected}
          selected={selected}
        />
      )}
    </div>
  );
};
