import { ThemeOptions, createTheme } from "@mui/material/styles";

export const colorPallette = {
  primary: "#a8bbfd",
  secondary: "#e8eeff",
  tertiary: "#f6f9fc",
  lightRed: "#fcb4ae",
  strongRed: "#b82100",
  greenHover: "#fcfcab",
  darkSecondary: "#d8e1fe",
  dark: "#5b55a0",
  white: "#fff",
  veryDark: "#141230",
  darkest: "#0A091A",
  green: "#50c950",
  yellow: "#fcfcab",
  red: "#d72802",
};
export const themeOptions: ThemeOptions = {
  palette: {
    mode: "light",
  },

  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "initial",
        },
        contained: {
          color: colorPallette.dark,
          fontWeight: "400",
          textTransform: "initial",
          fontSize: "0.8rem",
          height: "44px",
          backgroundColor: colorPallette.secondary,
          "&:hover": {
            backgroundColor: colorPallette.primary, // Background color on hover
            color: colorPallette.white,
          },
        },
      },
      defaultProps: {
        disableElevation: true,

        // The props to change the default for.
      },
    },
  },
};

export const theme = createTheme(themeOptions);

export const andesPallette = {
  main: "#5b55a0",
  primary: "#a8bbfd",
  secondary: "#e8eeff",
  tertiary: "#f6f9fc",
};

export const gradients = {
  dark: "linear-gradient(120deg, rgba(10,9,26,1) 0%, rgba(24,22,57,1) 90%, rgba(24,22,57,0.98) 100%)",
  light:
    "linear-gradient(90deg, rgba(168,187,253,1) 0%, rgba(246,249,252,1) 100%)",
};

export const cardStyle = {
  dark: {
    backgroundColor: colorPallette.veryDark,
    border: "solid 1px",
    borderColor: colorPallette.dark,
    color: "white",
  },
};
