import React from "react";
import { useForm } from "react-hook-form";
import { APIPostWithError } from "../../../Services/authenticated";
import { useAuth0 } from "@auth0/auth0-react";
import { setAppError, setLoading } from "../../../Utils/modalMethods";
import "./styles.css";
import { Document, updateDocumentOnAtom } from "../../../jotai/documents.jotai";
import AndesModal from "../../../uiComponents/AndesModal/AndesModal";
import AutomaticForm from "../../../uiComponents/Form/AutomaticForm";
import { Box } from "@mui/material";
import {
  commonComponentAtom,
  resetCommonComponent,
  setCommonComponents,
} from "../../../jotai/commonComponentAtom.jotai";
import { useAtomValue } from "jotai";

const ExpirationModal: React.FC = () => {
  const { currentDocument } = useAtomValue(commonComponentAtom);

  const { handleSubmit, control } = useForm();

  const { getAccessTokenSilently } = useAuth0();

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);

      // Verificar si expirationDate está en un formato válido
      const date = new Date(data.expirationDate);
      if (isNaN(date.getTime())) {
        throw new Error("Fecha inválida");
      }

      const dateToSave = date.getTime() + 14400000; // Ajustar el timezone si es necesario
      const accesToken = await getAccessTokenSilently();

      const res = await APIPostWithError("/doc-ref/edit-document", accesToken, {
        ...currentDocument,
        expirationDate: dateToSave, // Asegúrate de que esto sea un timestamp
        isExpirationHandled: false,
      });

      if (res.documentSaved) {
        const newDocument: Document = {
          ...currentDocument,
          expirationDate: String(dateToSave),
        } as Document;

        updateDocumentOnAtom(newDocument);
        resetCommonComponent();

        setTimeout(() => {
          setCommonComponents({
            successModal: {
              message: "Alarma creada exitósamente",
              submessage:
                "Podés ver tus próximos vencimientos en la sección ALARMAS",
              disclaimer: "*En la barra de navegación a la izquierda",
              show: true,
            },
          });
        }, 1000);
      } else {
        throw new Error("Error al guardar el documento");
      }
    } catch (error) {
      console.error(error);
      setAppError();
    } finally {
      setLoading(false);
    }
  };

  return (
    <AndesModal
      message="Crear vencimieto / alarma"
      handleSubmit={handleSubmit(onSubmit)}
      handleClose={resetCommonComponent}
    >
      <Box mt={2} width={"100%"}>
        <AutomaticForm
          control={control}
          fields={[
            {
              type: "plainDate",
              name: "expirationDate",
              label: "Fecha de vencimiento",
              required: true,
              validation: {
                validate: (value: string) =>
                  !isNaN(new Date(value).getTime()) || "Fecha inválida",
              },
            },
          ]}
        />
      </Box>
    </AndesModal>
  );
};

export default ExpirationModal;
