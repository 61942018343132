import React, { useState } from "react";
import ESignatureTable from "../../../../components/Tables/ESignature/ESignatureTable.component";
import AllSignaturesPage from "../AllSignaturesPage/AllSignaturesPage";
import { useAuth0 } from "@auth0/auth0-react";
import { setAppError, setLoading } from "../../../../Utils/modalMethods";
import { APIGetWithError } from "../../../../Services/authenticated";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp, faSignature } from "@fortawesome/free-solid-svg-icons";

import "../styles.css";
import { Box, Button } from "@mui/material";
import { setElectronicSignaturesAtom } from "../../../../jotai/electronicSignatures.jotai";
import TabSelector from "../../../../uiComponents/TabSelector/TabSelector";
import { setCommonComponents } from "../../../../jotai/commonComponentAtom.jotai";
import { Document } from "../../../../jotai/documents.jotai";

type NewElectronicSignatureScreenProps = {
  documents: Document[];
  setEsignDocumentData: any;
  company?: string;
};

const NewElectronicSignatureScreen: React.FC<
  NewElectronicSignatureScreenProps
> = ({ documents, setEsignDocumentData }) => {
  const [selectedPage, setSelectedPage] = useState<any>(0);
  const { getAccessTokenSilently } = useAuth0();

  const getSignatures = async () => {
    const accessToken = await getAccessTokenSilently();

    try {
      setLoading(true);
      const res = await APIGetWithError(
        "/electronic-signature/get-all-signatures-view-only",
        accessToken
      );
      if (res && res.signatures) {
        setElectronicSignaturesAtom(res.signatures);
      } else {
        setAppError();
      }
    } catch (error) {
      setAppError();
      return false;
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box sx={{ position: "absolute", zIndex: 9, top: 100 }}>
        <TabSelector
          options={["Estado de Firmas Generadas", "Nueva Firma"]}
          onChange={(index) => {
            setSelectedPage(index);
          }}
          currentValue={selectedPage}
          width={"420px"}
        />
      </Box>
      {selectedPage === 1 && (
        <Button
          onClick={() => {
            setCommonComponents({
              showFileUploadModal: true,
            });
          }}
          className="animate"
          variant="contained"
          startIcon={
            <FontAwesomeIcon
              icon={faCloudArrowUp}
              style={{ fontSize: "0.99rem" }}
            />
          }
          sx={{
            position: "absolute",
            right: "48px",
            top: "24px",
            width: "300px",
            height: "54px",
          }}
        >
          Cargar y firmar documento
        </Button>
      )}
      {selectedPage === 0 && (
        <Button
          onClick={() => {
            setSelectedPage(1);
          }}
          className="animate"
          variant="contained"
          startIcon={<FontAwesomeIcon icon={faSignature} />}
          sx={{
            position: "absolute",
            right: "48px",
            top: "24px",
            width: "300px",
            height: "54px",
          }}
        >
          Nueva Firma
        </Button>
      )}
      {documents && selectedPage === 1 && (
        <Box mt={11} className="animate">
          <ESignatureTable
            documents={documents}
            setEsignDocumentData={setEsignDocumentData}
          />
        </Box>
      )}
      {documents && selectedPage === 0 && (
        <Box mt={11} className="animate">
          <AllSignaturesPage getSignatures={getSignatures} />
        </Box>
      )}
    </>
  );
};

export default NewElectronicSignatureScreen;
