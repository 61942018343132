import { Box, Typography, Button, Stack, Modal } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { colorPallette } from "../../Common/theme";

type Props = {
  disclaimer?: string | null;
  message: string;
  subMessage?: string;
  handleClose?: () => void;
  handleConfirm?: () => void;
  handleReject?: () => void;
  handleSubmit?: () => void | Promise<void>;
  confirmButtonText?: string;
  submitButtonText?: string;
  children?: React.ReactNode;
};

const AndesModal: React.FC<Props> = ({
  message,
  subMessage,
  disclaimer = null,
  handleClose,
  handleConfirm,
  handleReject,
  handleSubmit,
  confirmButtonText,
  submitButtonText,
  children,
}) => {
  return (
    <Modal open>
      <Box
        sx={{
          position: "absolute",
          top: "10%",
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 9999,
          backgroundColor: "white",
          width: 600,
          borderRadius: 1,
          padding: 3,
          paddingTop: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {handleClose && (
          <Button
            onClick={handleClose}
            sx={{
              fontSize: "1.4rem",
              color: "rgb(170, 170, 228)",
              position: "absolute",
              top: 24,
              right: 8,
              "&:hover": {
                color: "rgb(107, 107, 158)",
              },
            }}
            endIcon={
              <FontAwesomeIcon icon={faTimes} style={{ paddingRight: 8 }} />
            }
          ></Button>
        )}

        {message && (
          <Typography
            variant="h5"
            sx={{
              pt: 2,
              fontWeight: "bold",
              textAlign: "left",
              fontSize: "1.3rem",
              width: "100%",
              paddingRight: "40px",
            }}
          >
            {message}
          </Typography>
        )}
        {subMessage && (
          <Typography
            variant="body1"
            sx={{
              margin: "20px",
              textAlign: "left",
              width: "100%",
            }}
          >
            {subMessage}
          </Typography>
        )}
        {disclaimer && (
          <Typography
            variant="body2"
            sx={{
              color: colorPallette.primary,
              fontSize: "0.8rem",
              textAlign: "left",
              width: "100%",
            }}
          >
            {disclaimer}
          </Typography>
        )}
        {children && children}
        {handleSubmit && (
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{ width: "100%", height: "54px" }}
            endIcon={
              <FontAwesomeIcon icon={faBolt} style={{ fontSize: "0.8rem" }} />
            }
          >
            {submitButtonText || "Enviar"}
          </Button>
        )}
        {(handleConfirm || handleReject) && (
          <Stack
            direction="row"
            sx={{
              mt: 4,
              width: "100%",
              justifyContent: "flex-end",
              padding: 0,
              "& button": {
                minWidth: "100px",
                height: 40,
              },
            }}
          >
            {handleReject && (
              <Button
                onClick={handleReject}
                sx={{
                  color: colorPallette.primary,
                  mr: 3,
                  fontWeight: "400",
                }}
                endIcon={<FontAwesomeIcon icon={faTimes} />}
              >
                Cancelar
              </Button>
            )}
            {handleConfirm && (
              <Button
                variant="contained"
                endIcon={<FontAwesomeIcon icon={faCircleCheck} />}
                onClick={handleConfirm}
              >
                {confirmButtonText || "Confirmar"}
              </Button>
            )}
          </Stack>
        )}
      </Box>
    </Modal>
  );
};

export default AndesModal;
