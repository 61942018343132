import { atom, getDefaultStore } from "jotai";

export type ElectronicSignature = {
  companyId: string;
  companyName: string;
  createdBy: string;
  creatorPhotoUrl: string;
  dateCreated: string;
  delFlg: number;
  documentId: string;
  documentToken: string;
  fileName: string;
  format: string;
  signatureId: string;
  signedFile?: string;
  signedFilePath: string | null;
  status: string;
  updateDate: string;
  userId: string;
};

type ElectronicSignaturesAtomType = {
  electronicSignatures: ElectronicSignature[];
};

const store = getDefaultStore();

export const initialElectronicSignaturesState: ElectronicSignaturesAtomType = {
  electronicSignatures: [],
};

export const electronicSignaturesAtom = atom<ElectronicSignaturesAtomType>(
  initialElectronicSignaturesState
);

export const setElectronicSignaturesAtom = (params: ElectronicSignature[]) => {
  store.set(electronicSignaturesAtom, {
    electronicSignatures: params,
  });
};

export const getElectronicSignaturesAtom = () => {
  return store.get(electronicSignaturesAtom);
};

export const updateElectronicSignatureOnAtom = (
  signatureToUpdate: ElectronicSignature
) => {
  const { signatureId } = signatureToUpdate;
  const { electronicSignatures } = store.get(electronicSignaturesAtom);

  if (electronicSignatures) {
    const updatedSignatures = electronicSignatures.map((sig: any) =>
      sig.signatureId === signatureId ? { ...signatureToUpdate } : sig
    );
    setElectronicSignaturesAtom(updatedSignatures);
  }
};

export const removeElectronicSignatureFromAtom = (
  signatureToRemove: ElectronicSignature
) => {
  const { signatureId } = signatureToRemove;
  const { electronicSignatures } = store.get(electronicSignaturesAtom);

  if (electronicSignatures) {
    const filteredSignatures = electronicSignatures.filter(
      (sig: any) => sig.signatureId !== signatureId
    );
    setElectronicSignaturesAtom(filteredSignatures);
  }
};

export const insertElectronicSignatureToAtom = (
  newSignature: ElectronicSignature
) => {
  const { electronicSignatures } = store.get(electronicSignaturesAtom);

  const updatedSignatures = electronicSignatures
    ? [...electronicSignatures, newSignature]
    : [newSignature];
  setElectronicSignaturesAtom(updatedSignatures);
};
