import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAtomValue } from "jotai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { Document, documentsAtom } from "../../../jotai/documents.jotai";
import AndesModal from "../../../uiComponents/AndesModal/AndesModal";
import { colorPallette } from "../../../Common/theme";

type Props = {
  onClose: () => void;
};

const filterAlarmDocuments = (documents: any[], range = 7) => {
  const today = Date.now();

  return documents.filter((doc) => {
    let expDate = doc.expirationDate;
    if (typeof expDate === "string") {
      expDate = parseInt(expDate);
    }
    const daysToExpiration = Math.floor(
      (expDate - today) / (1000 * 60 * 60 * 24)
    ); // Convert to days

    return daysToExpiration <= range && daysToExpiration >= -range;
  });
};

const AlarmsModal: React.FC<Props> = ({ onClose }) => {
  const history = useHistory();
  const { documents } = useAtomValue(documentsAtom);
  const [alarmDocuments, setAlarmDocuments] = useState<Document[]>([]);

  useEffect(() => {
    if (documents && documents.length) {
      const filteredDocuments = filterAlarmDocuments(documents, 7);
      const today = Date.now();

      const documentsWithDays = filteredDocuments
        .map((doc: any) => {
          let expDate = doc.expirationDate;
          if (typeof expDate === "string") {
            expDate = parseInt(expDate);
          }
          const daysToExpiration = Math.floor(
            (expDate - today) / (1000 * 60 * 60 * 24)
          ); // Convert to days
          const days = daysToExpiration + 1;

          return { ...doc, daysToExpiration: days };
        })
        .sort(
          (a: Document, b: Document) =>
            (a.daysToExpiration ?? 0) - (b.daysToExpiration ?? 0)
        );

      setAlarmDocuments(documentsWithDays);
    }
  }, [documents]);

  return (
    <AndesModal
      message={
        alarmDocuments.length > 0
          ? "Aviso de vencimientos recientes"
          : "No tienes vencimientos próximos pendientes"
      }
      handleSubmit={() => {
        history.push("/dashboard/alarms");
        onClose();
      }}
      handleClose={onClose}
      submitButtonText="Ver vencimientos"
    >
      <Box sx={{ mt: 2, pt: 0, overflowY: "auto", maxHeight: "60vh", mb: 2 }}>
        {alarmDocuments.length > 0 ? (
          alarmDocuments.map((doc) => (
            <Box
              key={doc.documentId}
              display="flex"
              justifyContent="space-between"
              sx={{
                py: 2,
                borderBottom: "solid 1px #e4eaf5",
                width: "540px",
              }}
              gap={10}
            >
              <Box
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "400px",
                  textAlign: "left",
                  fontSize: "0.85rem",
                }}
              >
                <FontAwesomeIcon
                  icon={faBell}
                  style={{
                    marginRight: "8px",
                    transform: "rotate(15deg)",
                    color: colorPallette.darkSecondary,
                  }}
                />
                {doc.fileName}
              </Box>
              {doc.daysToExpiration !== undefined && (
                <>
                  <Box
                    sx={{
                      fontSize: "0.85rem",
                      width: "40%",
                      // bgcolor: "red",
                      textAlign: "left",
                      fontWeight: "bold",
                    }}
                    color={
                      doc.daysToExpiration <= 0
                        ? colorPallette.lightRed
                        : "inherit"
                    }
                  >
                    {doc.daysToExpiration <= 0
                      ? `Hace ${-doc.daysToExpiration} días vencido`
                      : `Vence en ${doc.daysToExpiration} días`}
                  </Box>
                </>
              )}
            </Box>
          ))
        ) : (
          <Box sx={{ color: colorPallette.dark, my: 4, fontSize: "0.9rem" }}>
            No tienes vencimientos próximos pendientes
            <FontAwesomeIcon
              icon={faCircleCheck}
              style={{ marginLeft: "8px", color: colorPallette.darkSecondary }}
            />{" "}
          </Box>
        )}
      </Box>
    </AndesModal>
  );
};

export default AlarmsModal;
