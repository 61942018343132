import { useSelector } from "react-redux";
import ProfilePic from "../../uiComponents/ProfilePic/ProfilePic";
import { Box, Typography, Divider } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import React, { useEffect, useState } from "react";
import LogOutButton from "../LogOut/LogOutButton";
import { colorPallette } from "../../Common/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { useAtom } from "jotai";
import { userNameAtom } from "../../jotai/atoms.jotai";
import { AvatarLetter } from "../../uiComponents/ProfilePic/AvatarLetter";
import EditProfileNameModal from "../../components/Modals/EditProfileNameModal/EditProfileNameModal";

const Profile: React.FC = () => {
  const { user, company } = useSelector((state: any) => state.masterData);
  const [userName, setUserName] = useAtom(userNameAtom);
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    if (user && user.name) {
      setUserName({ name: user.name });
    }
  }, [user, setUserName]);

  return (
    <Box
      sx={{
        marginTop: "100px",
        display: "flex",
        justifyContent: "center",
      }}
      className="animate"
    >
      <Box
        sx={{
          backgroundColor: "#ffffff",
          width: "450px",
          borderRadius: "15px",
          padding: "15px 20px 20px",
          display: "flex",
          flexDirection: "column",
          boxShadow: "0px 14px 37px -8px rgba(0, 0, 0, 0.13)",
          alignItems: "center",
          margin: "40px 0",
        }}
      >
        <Box>
          {user?.photoUrl ? (
            <ProfilePic photoUrl={user.photoUrl} editable size={100} />
          ) : (
            <AvatarLetter name={user?.name} editable={true} size={100} />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: "7px",
            marginTop: "30px",
            gap: 1,
          }}
        >
          <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: 400,
            }}
          >
            {userName.name || "Nombre no disponible"}
          </Typography>
          <Box onClick={() => setOpenModal(true)} sx={{ cursor: "pointer" }}>
            <FontAwesomeIcon
              icon={faPenToSquare}
              color={colorPallette.primary}
            />
          </Box>
        </Box>

        {user?.email && (
          <Typography sx={{ fontSize: "0.95rem" }} color="primary">
            {user.email}
          </Typography>
        )}
        <Divider sx={{ width: "86%", margin: "30px 0" }} />
        <Box
          sx={{
            width: "86%",
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "15px",
          }}
        >
          <Typography>Empresa:</Typography>
          <Typography color="primary">
            {company?.companyName || "No Asignada"}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "86%",
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "15px",
          }}
        >
          <Typography>Rol:</Typography>
          <Typography color="primary">{user?.role || "No Asignado"}</Typography>
        </Box>
        <Box
          sx={{
            width: "86%",
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "55px",
          }}
        >
          <Typography>Suscripción:</Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <CheckCircleIcon
              sx={{ marginRight: "5px", color: colorPallette.green }}
            />{" "}
            <Typography component="span" color={colorPallette.green}>
              Activa
            </Typography>
          </Box>
        </Box>
        <LogOutButton />
      </Box>
      {openModal && (
        <EditProfileNameModal
          user={user}
          closeModal={() => setOpenModal(false)}
        />
      )}
    </Box>
  );
};

export default Profile;
