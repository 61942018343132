import ReactDOM from "react-dom/client";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter } from "react-router-dom";
import { registerLicense } from "@syncfusion/ej2-base";
import "./index.css";

// REDUX
import { Provider } from "react-redux";
import store from "./redux/store";
import { ThemeProvider } from "@mui/material";
import { theme } from "./Common/theme";

// Registrar la licencia de Syncfusion -> Comunity License
registerLicense(process.env.REACT_APP_SYNCFUSION_KEY || "");

// Validar que las variables de entorno estén definidas
const domain = process.env.REACT_APP_DOMAIN as string;
const clientId = process.env.REACT_APP_CLIENTID as string;
const audience = process.env.REACT_APP_AUDIENCE as string;

if (!domain || !clientId || !audience) {
  throw new Error("Faltan variables de entorno para Auth0");
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={`${window.location.origin}/login-redirect`}
      audience={audience}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </Auth0Provider>
  </Provider>
);
