import {
  AlignmentType,
  Footer,
  Header,
  ImageRun,
  PageNumber,
  Paragraph,
  TextRun,
} from "docx";
import { rp_soluciones_logo } from "../docImages/rp-soluciones";

export const styles_rp_soluciones = {
  companyId: ["12421"],
  companyName: "RP Soluciones Inmobiliarias",
  font: "Arial",
  titleSize: 22,
  textSize: 22,
  lineSpacing: 300,
  marginRight: 1300,
  marginLeft: 1300,
  beforeParagraph: 300,

  header: new Header({
    children: [
      new Paragraph({
        spacing: {
          before: 0,
          after: 450,
        },
        alignment: AlignmentType.CENTER,
        children: [
          new ImageRun({
            data: rp_soluciones_logo,
            transformation: {
              width: 100,
              height: 100,
            },
          }),
        ],
      }),
    ],
  }),

  footer: new Footer({
    children: [
      new Paragraph({
        spacing: {
          before: 300,
          after: 0,
        },
        alignment: AlignmentType.CENTER,
        children: [],
      }),
      new Paragraph({
        alignment: AlignmentType.RIGHT,
        children: [
          new TextRun({
            children: [PageNumber.CURRENT],
          }),
        ],
      }),
    ],
  }),
};
