import React from "react";
import "./TrustedCompanies.styles.css";
import CompaniesGrid from "../../../components/CompaniesGrid/CompaniesGrid";
import { faBolt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";

const logos = [
  "https://andes-photos.s3.us-east-2.amazonaws.com/flecha-bus-company-logo.png",
  "https://andes-photos.s3.us-east-2.amazonaws.com/soldati-company-logo.png",
  "https://andes-photos.s3.us-east-2.amazonaws.com/andesmar-home-logos.png",
  "https://andes-photos.s3.us-east-2.amazonaws.com/IZR-home-logo.png",
  "https://andes-photos.s3.us-east-2.amazonaws.com/okeefe-logo-home.png",
  "https://andes-photos.s3.us-east-2.amazonaws.com/click-aparts-logo.png",
  "https://andes-photos.s3.us-east-2.amazonaws.com/fabian-company-logo.png",
  "https://andes-photos.s3.us-east-2.amazonaws.com/chevallier-logo-home.png",
  "https://andes-photos.s3.us-east-2.amazonaws.com/guimat-logo-home.png",
  "https://andes-photos.s3.us-east-2.amazonaws.com/deinmobiliarios-company-logo.png",
  "https://andes-photos.s3.us-east-2.amazonaws.com/KellerWilliams_Prim_Logo_RGB.jpg",
  "https://andes-photos.s3.us-east-2.amazonaws.com/Baigun-company-logo.png",
  "https://andes-photos.s3.us-east-2.amazonaws.com/deinmobiliarios-company-logo.png",
  "https://andes-photos.s3.us-east-2.amazonaws.com/Cadema-company-logo.png",
  "https://andes-photos.s3.us-east-2.amazonaws.com/propicia-company-logo.png",
  "https://andes-photos.s3.us-east-2.amazonaws.com/guimat-company-logo.png",
  "https://andes-photos.s3.us-east-2.amazonaws.com/Logo-Flavia-Zavala.png",
  "https://andes-photos.s3.us-east-2.amazonaws.com/Pessah-company-logo.png",
  "https://andes-photos.s3.us-east-2.amazonaws.com/deinmobiliarios-company-logo.png",
  "https://andes-photos.s3.us-east-2.amazonaws.com/izr-company-logo.png",
  "https://andes-photos.s3.us-east-2.amazonaws.com/fabian-company-logo.png",
  "https://andes-photos.s3.us-east-2.amazonaws.com/okefee-company-logo.png",
  "https://andes-photos.s3.us-east-2.amazonaws.com/Coldwell+Banker-company-logo.png",
  "https://andes-photos.s3.us-east-2.amazonaws.com/Petagna-company-logo.png",
  "https://andes-photos.s3.us-east-2.amazonaws.com/Keller-Williams-Pilar-company-logo.png",
  "https://andes-photos.s3.us-east-2.amazonaws.com/loza-ordano-logo-company.png",
  "https://andes-photos.s3.us-east-2.amazonaws.com/Identidad-Inmobiliaria-company-logo.png",
];

const TrustedCompanies = () => {
  return (
    <Box sx={{ mb: { xs: "2px", md: "90px" } }}>
      <Box className="center-text f-standard" mb={"50px"}>
        <FontAwesomeIcon icon={faBolt} className="mr1 primary" />
        Confían en <span className="primary roboto bold">Andes Docs:</span>
      </Box>

      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <CompaniesGrid urls={logos} />
      </Box>

      <Box
        className="center"
        sx={{
          display: { xs: "none", md: "flex" },
          flexWrap: "wrap",
          justifyContent: "center",
          gap: 6,
          maxWidth: 1200,
        }}
      >
        {logos
          .filter((url, index, array) => array.indexOf(url) === index)
          .map((logo, index) => (
            <img
              key={index}
              src={logo}
              alt="logo-company"
              style={{
                width: "100px",
                height: "80px",
                objectFit: "contain",
                borderRadius: "4px",
                filter: "grayscale(20%)",
                opacity: 0.9,
              }}
            />
          ))}
      </Box>
    </Box>
  );
};

export default TrustedCompanies;
